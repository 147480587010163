import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import Dropzone from "react-dropzone";

import { countries } from "countries-list";

import withRouter from "../../components/Common/withRouter";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { createSelector } from "reselect";
import { color } from "echarts";

const UserProfile = (props) => {
  //meta title
  document.title = "Profile | ACUSOLO - Admin & Dashboard";

  const dispatch = useDispatch();

  const userprofileData = createSelector(
    (state) => state.Profile,
    (state) => ({
      error: state.error,
      success: state.success,
    })
  );

  // Inside your component
  const { error, success } = useSelector(userprofileData);

  const [gender, setgender] = useState("");
  const [birthday, setbirthday] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setemail] = useState("");
  const [userName, setname] = useState("");
  const [id, setid] = useState();
  const [errors, setErrors] = useState([{ phoneNumber: null }]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+1");
  const [selectedFiles, setselectedFiles] = useState([]);
  const authUser = localStorage.getItem("authUser");

  const countryList = Object.values(countries).map((country) => ({
    name: country.name,
    code: country.phone,
  }));

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // const countryList = [
  //   { name: "USA", code: "+1" },
  //   { name: "Canada", code: "+1" },
  //   { name: "UK", code: "+44" },
  //   { name: "Australia", code: "+61" },
  //   { name: "India", code: "+91" },
  //   // Add more countries as needed
  // ];

  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber.startsWith(selectedCountryCode)) {
      return "Phone number must start with the selected country code.";
    }
    return null;
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value;

    validation.handleChange(e);
    const error = validatePhoneNumber(phoneNumber);

    if (error) {
      validation.setFieldError("phoneNumber", error);
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: error }));
    } else {
      setErrors((prevErrors) => {
        const { phoneNumber, ...rest } = prevErrors; // Remove phoneNumber error if no error
        return rest;
      });
    }
  };

  useEffect(() => {
    // const authUser = localStorage.getItem("authUser");
    if (authUser) {
      const obj = JSON.parse(authUser);
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setid(obj.id);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "auth" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setbirthday(obj.birthday);
        setgender(obj.gender);
        setphoneNumber(obj.phoneNumber);
        setname(obj.userName);
        setemail(obj.email);
        setid(obj.id);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const formatDate = (isoString) => {
    if (isoString) {
      const date = new Date(isoString.split("T")[0]);
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    } else {
      return false;
    }
  };

  const today = new Date();
  const twelveYearsAgo = new Date(today.setFullYear(today.getFullYear() - 12));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      birthday: formatDate(birthday || JSON.parse(authUser).birthday),
      phoneNumber: phoneNumber || JSON.parse(authUser).phoneNumber,
      userName: userName || JSON.parse(authUser).userName,
      id: id || JSON.parse(authUser).id,
      email: email || JSON.parse(authUser).email,
      gender: gender || JSON.parse(authUser).gender,
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string().required("Please Enter Your PhoneNumber"),
      userName: Yup.string().required("Please Enter Your UserName"),
      email: Yup.string().required("Please Enter Your Email"),
      birthday: Yup.date()
        .max(new Date(), "Birthday cannot be in the future")
        .max(twelveYearsAgo, "You must be at least 12 years old")
        .required("Please enter your birthday"),
      gender: Yup.string()
        .required("Please select your gender")
        .notOneOf(["Select"], "Please choose a valid option"),
    }),

    onSubmit: (values) => {
      // const updateProfile = {
      //   id: values.id,
      //   name: values.name,
      //   description: values.description,
      //   price: values.price,
      // };

      console.log({ values });

      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      if (selectedFiles[0]) {
        formData.append("file", selectedFiles[0]);
      }

      // update profile
      dispatch(editProfile(formData, values.id));
      validation.resetForm();
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumb title="ACUSOLO" breadcrumbItem="Profile" /> */}

          <Row>
            <Col lg="12">
              {error.message && error ? (
                <Alert color="danger">{error.message}</Alert>
              ) : null}
              {success ? <Alert color="success">Success</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={
                          selectedFiles[0]?.preview || JSON.parse(authUser).url
                        }
                        // {...console.log({profile})}
                        alt=""
                        className="avatar-xl rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="text-muted">
                        <h5>{JSON.parse(authUser).userName}</h5>
                        <p className="mb-1">{JSON.parse(authUser).email}</p>
                        {/* <p className="mb-0">Admin{id}</p> */}
                      </div>
                      {/* <CardBody> */}
                      {/* <Form> */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="dropzone"
                            style={{ border: "0px  #000307FF", width: "10px" }}
                          >
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-1" style={{ width: "10px" }}>
                                <i className="display-10 bx bx-camera text-left" style={{color: "#0202B8FF"}}/>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change User Information</h4>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col lg={4} sm={6}>
                      <div className="form-group">
                        <Label className="form-label">User Name</Label>
                        <Input
                          name="userName"
                          className="form-control"
                          placeholder="Enter Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.userName || ""}
                          invalid={
                            validation.touched.userName &&
                            validation.errors.userName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.userName &&
                        validation.errors.userName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.userName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Input name="id" value={id} type="hidden" />
                    <Col lg={4} sm={6}>
                      <div className="form-group">
                        <div className="mb-3">
                          <Label
                            htmlFor="example-date-input"
                            className="form-Label"
                          >
                            Birthday
                          </Label>
                          <Input
                            name="birthday"
                            className="form-control"
                            placeholder="Enter Birthday"
                            type="date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.birthday || ""}
                            invalid={
                              validation.touched.birthday &&
                              validation.errors.birthday
                                ? true
                                : false
                            }
                          />
                          {validation.touched.birthday &&
                          validation.errors.birthday ? (
                            <FormFeedback type="invalid">
                              {validation.errors.birthday}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </Col>

                    <Col lg={4} sm={6}>
                      <div className="form-group">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                          disabled
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col lg={4} sm={6}>
                    <div className="form-group mb-3">
                      <Label className="form-label">Gender</Label>
                        <Input
                          name="gender"
                          label="Gender"
                          type="select"
                          placeholder="Select Gender"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.gender || ""}
                          invalid={
                            validation.touched.gender &&
                            validation.errors.gender
                              ? true
                              : false
                          }
                        >
                          <option value="Select">Select your gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </Input>
                        {validation.touched.gender &&
                        validation.errors.gender ? (
                          <FormFeedback type="invalid">
                            {validation.errors.gender}
                          </FormFeedback>
                        ) : null}
                      </div>
                      {/* <div className="form-group mb-3">
                        <Label className="form-label">Gender</Label>
                        <select
                          className={`form-control ${
                            validation.touched.gender &&
                            validation.errors.gender
                              ? "is-invalid"
                              : ""
                          }`}
                          name="gender"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.gender}
                        >
                          <option value="Select">Select your gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>

                        {validation.touched.gender &&
                        validation.errors.gender ? (
                          <FormFeedback type="invalid">
                            {validation.errors.gender}
                          </FormFeedback>
                        ) : null}
                      </div> */}
                    </Col>
                    <Col lg={4} sm={6}>
                      <div className="form-group">
                        <Label className="form-label mr-2">Phone Number</Label>
                        <Row>
                          <Col lg={4} sm={4}>
                            <Input
                              type="select"
                              onChange={(e) =>
                                setSelectedCountryCode(e.target.value)
                              }
                              value={selectedCountryCode}
                            >
                              {countryList.map((country, index) => (
                                <option
                                  key={`${country.code}-${index}`}
                                  value={country.code}
                                >
                                  {country.name} ({country.code})
                                </option>
                              ))}
                            </Input>
                          </Col>
                          <Col lg={8} sm={8}>
                            <Input
                              name="phoneNumber"
                              className="form-control"
                              placeholder="Enter Phone Number"
                              type="text"
                              onChange={handlePhoneNumberChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.phoneNumber || ""}
                              invalid={
                                (validation.touched.phoneNumber &&
                                  validation.errors.phoneNumber) ||
                                errors.phoneNumber
                                  ? true
                                  : false
                              }
                              // invalid={
                              //   validation.touched.phoneNumber &&
                              //   validation.errors.phoneNumber
                              //     ? true
                              //     : false
                              // }
                            />
                            {validation.touched.phoneNumber &&
                            validation.errors.phoneNumber ? (
                              <FormFeedback
                                type="invalid"
                                style={{ display: "block" }}
                              >
                                {validation.errors.phoneNumber}
                              </FormFeedback>
                            ) : errors.phoneNumber ? (
                              <FormFeedback
                                type="invalid"
                                style={{ display: "block" }}
                              >
                                {errors.phoneNumber}
                              </FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <div className="text-center mt-4">
                      <Button type="submit" color="danger">
                        Update User Information
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
