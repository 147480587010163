import {
  GET_PROGRAM_PROFILE,
  GET_PROGRAM_PROFILE_FAIL,
  GET_PROGRAM_PROFILE_SUCCESS,
  GET_PROGRAMS,
  GET_PROGRAMS_FAIL,
  GET_PROGRAMS_SUCCESS,
  ADD_PROGRAM_ANSWER_SUCCESS,
  GET_PROGRAMS_BY_ID,
  ADD_NEW_PROGRAM,
  ADD_NEW_PROGRAM_ANSWER,
  ADD_PROGRAM_SUCCESS,
  ADD_PROGRAM_FAIL,
  UPDATE_PROGRAM,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_FAIL,
  DELETE_PROGRAM,
  DELETE_PROGRAM_SUCCESS,
  DELETE_PROGRAM_FAIL,
} from "./actionTypes"

export const getPrograms = () => ({
  type: GET_PROGRAMS,
})

export const getProgramsById = programs => ({
  type: GET_PROGRAMS_BY_ID,
  payload: programs,
})

export const getProgramsSuccess = programs => ({
  type: GET_PROGRAMS_SUCCESS,
  payload: programs,
})

export const addNewProgram = program => ({
  type: ADD_NEW_PROGRAM,
  payload: program,
})

export const addNewProgramAnswer = program => ({
  type: ADD_NEW_PROGRAM_ANSWER,
  payload: program,
})


export const addProgramSuccess = program => ({
  type: ADD_PROGRAM_SUCCESS,
  payload: program,
})

export const addProgramAnswerSuccess = (programAnswer) => ({
  type: ADD_PROGRAM_ANSWER_SUCCESS,
  payload: programAnswer,
})

export const addProgramFail = error => ({
  type: ADD_PROGRAM_FAIL,
  payload: error,
})

export const getProgramsFail = error => ({
  type: GET_PROGRAMS_FAIL,
  payload: error,
})

export const getProgramProfile = () => ({
  type: GET_PROGRAM_PROFILE,
})

export const getProgramProfileSuccess = programProfile => ({
  type: GET_PROGRAM_PROFILE_SUCCESS,
  payload: programProfile,
})

export const getProgramProfileFail = error => ({
  type: GET_PROGRAM_PROFILE_FAIL,
  payload: error,
})

export const updateProgram = program => ({
  type: UPDATE_PROGRAM,
  payload: program,
})

export const updateProgramSuccess = program => ({
  type: UPDATE_PROGRAM_SUCCESS,
  payload: program,
})

export const updateProgramFail = error => ({
  type: UPDATE_PROGRAM_FAIL,
  payload: error,
})

export const deleteProgram = program => ({
  type: DELETE_PROGRAM,
  payload: program,
})

export const deleteProgramSuccess = program => ({
  type: DELETE_PROGRAM_SUCCESS,
  payload: program,
})

export const deleteProgramFail = error => ({
  type: DELETE_PROGRAM_FAIL,
  payload: error,
})
