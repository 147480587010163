import { patch, call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_PROGRAMS,
  GET_PROGRAMS_BY_ID,
  GET_PROGRAM_PROFILE,
  ADD_NEW_PROGRAM,
  ADD_NEW_PROGRAM_ANSWER,
  DELETE_PROGRAM,
  UPDATE_PROGRAM,
} from "./actionTypes";

import {
  getProgramsSuccess,
  getProgramsFail,
  getProgramProfileSuccess,
  getProgramProfileFail,
  addProgramFail,
  addProgramSuccess,
  addProgramAnswerSuccess,
  updateProgramSuccess,
  updateProgramFail,
  deleteProgramSuccess,
  deleteProgramFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getPrograms,
  getProgramsById,
  getProgramProfile,
  addNewProgram,
  addNewProgramAnswer,
  updateProgram,
  deleteProgram,
} from "../../helpers/helper";

function* fetchPrograms() {
  try {
    const response = yield call(getPrograms);
    yield put(getProgramsSuccess(response));
  } catch (error) {
    yield put(getProgramsFail(error));
  }
}

function* fetchProgramsById({ payload: program }) {
  try {
    const response = yield call(getPrograms);
    yield put(getProgramsSuccess(response));
  } catch (error) {
    yield put(getProgramsFail(error));
  }
}

function* fetchProgramProfile() {
  try {
    const response = yield call(getProgramProfile);
    yield put(getProgramProfileSuccess(response));
  } catch (error) {
    yield put(getProgramProfileFail(error));
  }
}

function* onUpdateProgram({ payload: program }) {
  try {
    // delete program.id
    const id = program.id;
    const response = yield call(updateProgram, program, id);
    yield put(updateProgramSuccess(response));
  } catch (error) {
    yield put(updateProgramFail(error));
  }
}

function* onDeleteProgram({ payload: program }) {
  try {
    const response = yield call(deleteProgram, program);
    yield put(deleteProgramSuccess(response));
  } catch (error) {
    yield put(deleteProgramFail(error));
  }
}

function* onAddNewProgram({ payload: program }) {
  try {
    const response = yield call(addNewProgram, program);

    yield put(addProgramSuccess(response));
  } catch (error) {
    yield put(addProgramFail(error));
  }
}

function* onAddNewProgramAnswer({ payload: program }) {
  try {
    const response = yield call(addNewProgramAnswer, program);

    yield put(addProgramAnswerSuccess(response));
  } catch (error) {
    yield put(addProgramFail(error));
  }
}

function* programsSaga() {
  yield takeEvery(GET_PROGRAMS, fetchPrograms);
  yield takeEvery(GET_PROGRAMS_BY_ID, fetchProgramsById);
  yield takeEvery(GET_PROGRAM_PROFILE, fetchProgramProfile);
  yield takeEvery(ADD_NEW_PROGRAM, onAddNewProgram);
  yield takeEvery(ADD_NEW_PROGRAM_ANSWER, onAddNewProgramAnswer);
  yield takeEvery(UPDATE_PROGRAM, onUpdateProgram);
  yield takeEvery(DELETE_PROGRAM, onDeleteProgram);
}

export default programsSaga;
