import { patch, call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_DEVICES, GET_DEVICES_BY_ID, GET_DEVICE_PROFILE, ADD_NEW_DEVICE, DELETE_DEVICE, UPDATE_DEVICE } from "./actionTypes"

import {
  getDevicesSuccess,
  getDevicesFail,
  getDeviceProfileSuccess,
  getDeviceProfileFail,
  addDeviceFail,
  addDeviceSuccess,
  updateDeviceSuccess,
  updateDeviceFail,
  deleteDeviceSuccess,
  deleteDeviceFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getDevices, getDevicesById, getDeviceProfile, addNewDevice, updateDevice, deleteDevice } from "../../helpers/helper"

function* fetchDevices() {
  try {
    const response = yield call(getDevices)
    yield put(getDevicesSuccess(response))
  } catch (error) {
    yield put(getDevicesFail(error))
  }
}

function* fetchDevicesById({payload: device}) {
  try {
    const response = yield call(getDevices)
    yield put(getDevicesSuccess(response))
  } catch (error) {
    yield put(getDevicesFail(error))
  }
}

function* fetchDeviceProfile() {
  try {
    const response = yield call(getDeviceProfile)
    yield put(getDeviceProfileSuccess(response))
  } catch (error) {
    yield put(getDeviceProfileFail(error))
  }
}

function* onUpdateDevice({ payload: device }) {
  try {
    // delete device.id
    const id = device.get('id');

    const response = yield call(updateDevice, device, id)
    yield put(updateDeviceSuccess(response))
  } catch (error) {
    yield put(updateDeviceFail(error))
  }
}

function* onDeleteDevice({ payload: device }) {
  try {
    const response = yield call(deleteDevice, device)
    yield put(deleteDeviceSuccess(response))
  } catch (error) {
    yield put(deleteDeviceFail(error))
  }
}

function* onAddNewDevice({ payload: device }) {

  try {
    const response = yield call(addNewDevice, device)

    yield put(addDeviceSuccess(response))
  } catch (error) {

    yield put(addDeviceFail(error))
  }
}

function* devicesSaga() {
  yield takeEvery(GET_DEVICES, fetchDevices)
  yield takeEvery(GET_DEVICES_BY_ID, fetchDevicesById)
  yield takeEvery(GET_DEVICE_PROFILE, fetchDeviceProfile)
  yield takeEvery(ADD_NEW_DEVICE, onAddNewDevice)
  yield takeEvery(UPDATE_DEVICE, onUpdateDevice)
  yield takeEvery(DELETE_DEVICE, onDeleteDevice)
}

export default devicesSaga;
