import axios from "axios";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import TableContainer from "../../../components/Common/TableContainer";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  FormFeedback,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";
import Dropzone from "react-dropzone";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Name,
  Email,
  Description,
  Day,
  Tags,
  UpdatedAt,
  CreatedAt,
  Projects,
  Img,
} from "./sessionlistCol";

import ReactLoading from "react-loading";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getSessions as onGetSessions,
  getSessionsById as onGetSessionsById,
  addNewSession as onAddNewSession,
  updateSession as onUpdateSession,
  deleteSession as onDeleteSession,
} from "../../../store/actions";

import {
  getPrograms as onGetPrograms,
  getProgramsById as onGetProgramsById,
} from "../../../store/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const sessionsList = () => {
  document.title = "ACUSOLO | Admin & Dashboard";

  const durationnotify = () =>
    toast("Please select the program. ", {
      position: "top-center",
      hideProgressBar: false,
      className: "bg-danger text-white",
    });
  const errornotify = () =>
    toast("Error ! An error occurred.", {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-danger text-white",
      transition: Slide,
    });
  const editorRef = useRef();
  const [editor, setEditor] = useState(false);
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  // const [ state, setState ]  = useState("All");
  const [selectedValue, setSelectedValue] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedName, setSelectedName] = useState("All");
  const dispatch = useDispatch();
  const [session, setSession] = useState();
  const [program, setprogram] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const onSelectData = (value, name) => {
    setSelectedValue(value);
    setSelectedName(name);
    // setState(value);
    // dispatch(getPrograms(value));
    dispatch(onGetSessionsById(value));
  };

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    setEditor(true);
  }, []);
  const [data, setData] = useState("");
  const [editorData, setEditorData] = useState("");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();

    setEditorData(data);
    setSession({
      ...session,
      description: data,
    });
  };

  // const handleEditorChange = (event, editor) => {
  //   const data = editor.getData();
  //   console.log(session);
  //   setEditorData(data);
  //   setSession((prevSession) => ({
  //     ...prevSession,
  //     description: data,
  //   }));
  // };

  const handleSelectChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    // console.log(selectedOption);

    const value = selectedOption.value;
    const name = selectedOption.text; // This gets the text inside the option
    onSelectData(value, name);
  };

  const handleSelectDayChange = (Dayevent) => {
    const selectedOption = Dayevent.target.options[Dayevent.target.selectedIndex];
    const value = selectedOption.value;
    const name = selectedOption.text;    
    setSelectedDay(value);
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      pid: (session && session.pid) || "",
      pname: (session && session.pname) || "",
      name: (session && session.name) || "",
      day: (session && session.day) || "",
      description: (session && session.description) || "",
      image: (selectedFiles && selectedFiles[0]) || null,

    },
    validationSchema: Yup.object({
      pname: Yup.string().required("Please Enter Program Name"),
      name: Yup.string().required("Please Enter Session Name"),
      description: Yup.string().required("Please Enter Session Description"),
      image: Yup.mixed().required('Please upload an image'),

    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateSession = {
          id: session.id,
          name: values.name,
          day: values.day,
          description: values.description,
        };

        const formData = new FormData();

        Object.keys(updateSession).forEach((key) => {
          formData.append(key, updateSession[key]);
        });

        if (selectedFiles[0]) {
          formData.append("file", selectedFiles[0]);
        }

        // update session
        dispatch(onUpdateSession(formData, updateSession.id));
        validation.resetForm();
        setIsEdit(false);

      } else {
        const newSession = {
          pid: values["pid"],
          name: values["name"],
          day: values["day"],
          description: values["description"],
        };

        const formData = new FormData();

        Object.keys(newSession).forEach((key) => {
          formData.append(key, newSession[key]);
        });

        if (selectedFiles[0]) {
          formData.append("file", selectedFiles[0]);
        }    

        // save new session
        dispatch(onAddNewSession(formData));
        validation.resetForm();
      }
      toggle();
    },
  });

  const options = [];  

    for (let i = 2; i <= 20; i++) {  
        options.push(<option key={i} value={i}>{i} days</option>);  
    }

  const sessionlistData = createSelector(
    (state) => state.sessions,
    (state) => ({
      sessions: state.sessions,
      loading: state.loading,
    })
  );

  const programlistData = createSelector(
    (state) => state.programs,
    (state) => ({
      programs: state.programs,
    })
  );

  // Inside your component
  const { sessions, loading } = useSelector(sessionlistData);
  const { programs } = useSelector(programlistData);

  const columns = useMemo(
    () => [
      {  
        Header: "No",  
        Cell: ({ row, state }) => {  
          const { pageIndex, pageSize } = state; // Get pageIndex and pageSize from state  
          const rowIndex = row.index + 1; // Calculate the row number  
          return <label className="">{rowIndex}</label>;  
        },  
      },
      {
        Header: "Img",
        accessor: "url",
        filterable: false,
        disableFilters: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Img {...cellProps} />;
        },
      },
      {
        Header: "Session Name",
        filterable: false,
        disableFilters: true,
        accessor: "name",
        Cell: (session) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {session.row.original.name}
              </Link>
            </h5>
            <p className="text-muted mb-0">
              {session.row.original.designation}
            </p>
          </>
        ),
      },
      {
        Header: "Day",
        accessor: "day",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Day value={cellProps.value} />;
        },
      },
      {
        Header: "Session Description",
        accessor: "description",
        width: "70%",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Description value={cellProps.value} />;
        },
      },
      {
        Header: "CreatedAt",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CreatedAt {...cellProps} />;
        },
      },

      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <UpdatedAt {...cellProps} />;
        },
      },
      // {
      //   Header: "Tags",
      //   accessor: "tags",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return <Tags {...cellProps} />;
      //   },
      // },
      // {
      //   Header: "createdAt",
      //   accessor: "createdAt",
      //   filterable: false,
      //   disableFilters: true,
      //   width: "20%",
      //   Cell: (cellProps) => {
      //     return (
      //       <>
      //         <createdAt {...cellProps} />{" "}
      //       </>
      //     );
      //   },
      // },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const sessionData = cellProps.row.original;
                    handleSessionClick(sessionData);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const sessionData = cellProps.row.original;
                    onClickDelete(sessionData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (programs && !programs.length && !isEmpty(programs)) {
      dispatch(onGetPrograms());
      setIsEdit(false);
    }
  }, [dispatch, programs]);

  useEffect(() => {
    if (sessions && !sessions.length && !isEmpty(sessions)) {
      dispatch(onGetSessions());
      setIsEdit(false);
    }
  }, [dispatch, sessions]);

  useEffect(() => {
    dispatch(onGetSessions());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    dispatch(onGetPrograms());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    setSession(sessions);
    setIsEdit(false);
  }, [sessions]);

  useEffect(() => {
    setprogram(programs);
    // setIsEdit(false);
  }, [programs]);

  useEffect(() => {
    if (!isEmpty(sessions) && !!isEdit) {
      setSession(sessions);
      setIsEdit(false);
    }
  }, [sessions]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSessionClick = (arg) => {
    const session = arg;
    setSession({
      id: session.id,
      pname: session.pname,
      name: session.name,
      day: session.day,
      description: session.description,
    });

    setIsEdit(true);

    toggle();
  };

  const handleSessionClicks = (result) => {
    // const session = arg;
    setSession({
      pid: result.selectedValue,
      pname: result.selectedName,
      // description: result.editorData,
    });

    setIsEdit(false);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete session
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (sessions) => {
    setSession(sessions);
    setDeleteModal(true);
  };

  const handleDeleteSession = () => {
    dispatch(onDeleteSession(session.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  // const handleSessionClicks = () => {
  //   setIsEdit(false);
  //   toggle();
  // };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSession}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="sessions" breadcrumbItem="Session List" /> */}
          <Row>
            <Col>
              <Col lg="12">
                <Row className="align-items-center">
                  <Col>
                    <div className="mb-3">
                      <h5 className="card-title">
                        Sessions List{" "}
                        <span className="text-muted fw-normal ms-2">
                          ({sessions.length})
                        </span>
                      </h5>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                      {/* <div>
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <NavLink className="active" href="/sessions-list" data-bs-toggle="tooltip"  data-bs-placement="top" id="list">
                            <i className="bx bx-list-ul"></i>
                            <UncontrolledTooltip placement="top" target="list"> List</UncontrolledTooltip>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink  href="/sessions-grid"  data-bs-toggle="tooltip" data-bs-placement="top" id="grid"  >
                            <i className="bx bx-grid-alt"></i>
                            <UncontrolledTooltip placement="top" target="grid">
                              Grid
                            </UncontrolledTooltip>
                          </NavLink>
                        </li>
                      </ul>
                    </div> */}

                      <Card>
                        <CardHeader>
                          {/* <h4 className="card-title mb-1">Session Editor</h4> */}
                          <p className="text-muted mb-0">
                          Please <code>select</code> program. Before add new
                          session, check selection Program.
                          </p>
                          <br></br>
                          <div className="row">
                            <div className="col-sm-7 col-md-7">
                              <div className="">
                                {/* <h5 className="card-title me-2">Program : </h5> */}
                                <div className="ms-auto">
                                  <select
                                    className="form-select form-select-md"
                                    onChange={handleSelectChange}
                                  >
                                    <option value="00000000-0000-0000-0000-000000000000">
                                      Select Programs...
                                    </option>
                                    {programs.map((program) => (
                                      <option
                                        key={program.id}
                                        value={program.id}
                                      >
                                        {program.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* <div style={{display: 'flex'}} className="justify-content-end col-sm-6"> */}
                            <div
                              style={{ display: "flex" }}
                              className="col-sm-5 col-md-5 justify-content-end"
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                // onClick={handleSessionClicks}
                                onClick={() => {
                                  if (
                                    selectedValue == "All" ||
                                    selectedValue ==
                                      "00000000-0000-0000-0000-000000000000"
                                  ) {
                                    durationnotify();
                                    // errornotify();
                                  } else {
                                    const result = {
                                      editorData,
                                      selectedName,
                                      selectedValue,
                                    };
                                    handleSessionClicks(result);
                                  }
                                }}
                              >
                                <i className="bx bx-plus me-1"></i> Add new
                              </Link>
                            </div>
                          </div>
                        </CardHeader>
                        {/* <CardBody>
                  
                        </CardBody> */}
                      </Card>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={12}>
                    <TableContainer
                      columns={columns}
                      data={sessions}
                      isGlobalFilter={true}
                      isAddSessionList={true}
                      customPageSize={10}
                      className="table align-middle datatable dt-responsive table-check nowrap"
                    />

                    <Modal isOpen={modal} toggle={toggle}>
                      <ModalHeader toggle={toggle} tag="h4">
                        {!!isEdit ? "Edit Session" : "Add Session"}
                      </ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <Row>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">Program</Label>
                                <Input
                                  name="pname"
                                  label="Pname"
                                  type="text"
                                  disabled
                                  placeholder="Insert Program"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.pname || ""}
                                  invalid={
                                    validation.touched.pname &&
                                    validation.errors.pname
                                      ? true
                                      : false
                                  }
                                />
                              </div>

                              <div className="mb-3">
                              <Label className="form-label">Number of Days</Label>
                              <Input
                                name="day"
                                label="Day"
                                type="select"
                                placeholder="Insert Day"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.day || ""
                                }
                                invalid={
                                  validation.touched.day &&
                                  validation.errors.day
                                    ? true
                                    : false
                                }
                              >
                                <option value="Free">
                                  Select Days
                                </option>
                                <option value="1">
                                  1 day
                                </option>
                                {options}
                              </Input>
                              {validation.touched.day &&
                              validation.errors.day ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.day}
                                </FormFeedback>
                              ) : null}
                            </div>

                              {/* <div className="mb-3">
                                <Label className="form-label">Day</Label>
                                <div className="ms-auto">
                                  <select
                                    className="form-select form-select-md"
                                    onChange={handleSelectDayChange}
                                  >
                                    <option value="00000000-0000-0000-0000-000000000000">
                                      Select Day...
                                    </option>
                                    {days.map((program) => (
                                      <option
                                        key={program.id}
                                        value={program.id}
                                      >
                                        {program.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                            </div> */}

                              <div className="mb-3">
                                <Col className="col-12">
                                  <Label className="form-label">Image</Label>
                                  <Card>
                                    {/* <CardHeader>
                                    <h4 className="card-title">Dropzone</h4>
                                    <p className="card-title-desc">
                                      DropzoneJS is an open source library that
                                      provides drag’n’drop file uploads with
                                      image previews.
                                    </p>
                                  </CardHeader> */}
                                    <CardBody>
                                      {/* <Form> */}
                                      <Dropzone
                                        onDrop={(acceptedFiles) => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick mt-2"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-1">
                                                <i className="display-6 text-muted bx bx-cloud-upload" />
                                                <h6>
                                                  Drop files here or click to
                                                  upload.
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div
                                        className="dropzone-previews mt-3"
                                        id="file-previews"
                                      >
                                        {selectedFiles.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center col-12">
                                                  <Col xs={12}>
                                                    <Col className="col-auto">
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="300"
                                                        className="avatar-lg rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                      />
                                                      <span
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <Link
                                                          to="#"
                                                          className="text-muted font-weight-bold"
                                                        >
                                                          &nbsp;{f.name}
                                                        </Link>

                                                        {/* <p className=""> */}
                                                        <strong>
                                                          &nbsp;
                                                          {"(" +
                                                            f.formattedSize +
                                                            ")"}
                                                        </strong>
                                                        {/* </p> */}
                                                      </span>
                                                    </Col>
                                                  </Col>
                                                  {/* <Col xs={3}>
                                                    <div className="text-center mt-4">
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary "
                                                      >
                                                        Upload
                                                      </button>
                                                    </div>
                                                  </Col> */}
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                      {/* </Form> */}
                                    </CardBody>
                                    { validation.touched.image && validation.errors.image && !selectedFiles[0] ? (
                                    <FormFeedback type="invalid" className="d-block">  
                                    {validation.errors.image}  
                                  </FormFeedback>
                                    ) : null}
                                  </Card>
                                </Col>
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">
                                  Session Name
                                </Label>
                                <Input
                                  name="name"
                                  type="text"
                                  placeholder="Insert Session Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    validation.touched.name &&
                                    validation.errors.name
                                      ? true
                                      : false
                                  }
                                />
                                {/* {validation.touched.name &&
                                validation.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.name}
                                  </FormFeedback>
                                ) : null} */}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">
                                  Description
                                </Label>
                                {/* <div className="form-control">
                                  {editor ? (
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={
                                        !!isEdit
                                          ? validation.values.description
                                          : ""
                                      }
                                      onReady={() => {
                                        // You can store the "editor" and use when it is needed.
                                      }}
                                      onChange={handleEditorChange}
                                    />
                                  ) : (
                                    <p>Loading editor.....</p>
                                  )}
                                </div> */}
                                <br></br>
                                {/* <Label className="form-label">Html text</Label> */}
                                <Input
                                  name="description"
                                  label="Description"
                                  type="text"
                                  placeholder="Insert Description"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.description || ""}
                                  invalid={
                                    validation.touched.description &&
                                    validation.errors.description
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.description &&
                                validation.errors.description ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.description}
                                  </FormFeedback>
                                ) : null}

                                <Input
                                  name="pid"
                                  label="Pid"
                                  type="text"
                                  placeholder="Insert Program"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.pid || ""}
                                  invalid={
                                    validation.touched.pid &&
                                    validation.errors.pid
                                      ? true
                                      : false
                                  }
                                  style={{ visibility: "hidden" }}
                                />
                                {validation.touched.pid &&
                                validation.errors.pid ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.pid}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary save-session"
                                >
                                  {!!isEdit ? "Save" : "Create"}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </Col>
                </Row>
              </Col>
            </Col>
            {/* <Col xl={4}>
            </Col> */}
          </Row>
          <ToastContainer />
          {loading && (
            <div className="loading-container">
              <div className="loading">
                <ReactLoading
                  type="spinningBubbles"
                  color="#007bff"
                  height={100}
                  width={100}
                />
              </div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default sessionsList;
