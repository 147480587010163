import {
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  GET_CATEGORY_PROFILE_SUCCESS,
  GET_CATEGORY_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  categories: [],
  categoryProfile: {},
  error: {},
}

const categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      }

    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CATEGORY_SUCCESS:

      return {
        ...state,
        categories: [action.payload, ...state.categories ],
      }

    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CATEGORY_PROFILE_SUCCESS:
      return {
        ...state,
        categoryProfile: action.payload,
      }

    case UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.map(category =>
          category.id.toString() === action.payload.id.toString()
            ? { category, ...action.payload }
            : category
        ),
      }

    case UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: state.categories.filter(
          category => category.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CATEGORY_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default categories
