import {
  GET_ANSWERS_SUCCESS,
  GET_ANSWERS_FAIL,
  ADD_ANSWER_SUCCESS,
  ADD_ANSWER_FAIL,
  UPDATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_FAIL,
  DELETE_ANSWER_SUCCESS,
  DELETE_ANSWER_FAIL,
  GET_ANSWER_PROFILE_SUCCESS,
  GET_ANSWER_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  answers: [],
  answerProfile: {},
  error: {},
}

const answers = (state = INIT_STATE, action) => {
  
  switch (action.type) {
    case GET_ANSWERS_SUCCESS:
      return {
        ...state,
        answers: action.payload,
      }

    case GET_ANSWERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_ANSWER_SUCCESS:

      return {
        ...state,
        answers: [action.payload, ...state.answers ],
      }

    case ADD_ANSWER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ANSWER_PROFILE_SUCCESS:
      return {
        ...state,
        answerProfile: action.payload,
      }

    case UPDATE_ANSWER_SUCCESS:
      return {
        ...state,
        answers: state.answers.map(answer =>
          answer.id.toString() === action.payload.id.toString()
            ? { answer, ...action.payload }
            : answer
        ),
      }

    case UPDATE_ANSWER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ANSWER_SUCCESS:
      return {
        ...state,
        answers: state.answers.filter(
          answer => answer.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_ANSWER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ANSWER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default answers
