import {
  GET_POINT12S_SUCCESS,
  GET_POINT12S_FAIL,
  ADD_POINT12_SUCCESS,
  ADD_POINT12_FAIL,
  UPDATE_POINT12,
  UPDATE_POINT12_SUCCESS,
  UPDATE_POINT12_FAIL,
  DELETE_POINT12_SUCCESS,
  DELETE_POINT12_FAIL,
  GET_POINT12_PROFILE_SUCCESS,
  GET_POINT12_PROFILE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  points12: [],
  point12Profile: {},
  error: {},
  loading: false,
};

const points12 = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_POINT12S_SUCCESS:
      return {
        ...state,
        points12: action.payload,
      };

    case GET_POINT12S_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_POINT12_SUCCESS:
      return {
        ...state,
        points12: [action.payload, ...state.points12 ],
      };

    case ADD_POINT12_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_POINT12_PROFILE_SUCCESS:
      return {
        ...state,
        point12Profile: action.payload,
      };

    case UPDATE_POINT12:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_POINT12_SUCCESS:
      return {
        ...state,
        points12: state.points12.map((point12) =>
          point12.id.toString() === action.payload.id.toString()
            ? { point12, ...action.payload }
            : point12
        ),
        loading: false,
        error: null,
      };

    case UPDATE_POINT12_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case DELETE_POINT12_SUCCESS:
      return {
        ...state,
        points12: state.points12.filter(
          (point12) => point12.id.toString() !== action.payload.toString()
        ),
      };

    case DELETE_POINT12_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_POINT12_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default points12;
