/* SESSIONS */
export const GET_SESSIONS = "GET_SESSIONS"
export const GET_SESSIONS_BY_ID = "GET_SESSIONS_BY_ID"
export const GET_SESSIONS_SUCCESS = "GET_SESSIONS_SUCCESS"
export const GET_SESSIONS_FAIL = "GET_SESSIONS_FAIL"

/* SESSIONS PROFILE */
export const GET_SESSION_PROFILE = "GET_SESSION_PROFILE"
export const GET_SESSION_PROFILE_SUCCESS = "GET_SESSION_PROFILE_SUCCESS"
export const GET_SESSION_PROFILE_FAIL = "GET_SESSION_PROFILE_FAIL"

/**
 * add session
 */
export const ADD_NEW_SESSION = "ADD_NEW_SESSION"
export const ADD_SESSION_SUCCESS = "ADD_SESSION_SUCCESS"
export const ADD_SESSION_FAIL = "ADD_SESSION_FAIL"

/**
 * Edit session
 */
export const UPDATE_SESSION = "UPDATE_SESSION"
export const UPDATE_SESSION_SUCCESS = "UPDATE_SESSION_SUCCESS"
export const UPDATE_SESSION_FAIL = "UPDATE_SESSION_FAIL"

/**
 * Delete session
 */
export const DELETE_SESSION = "DELETE_SESSION"
export const DELETE_SESSION_SUCCESS = "DELETE_SESSION_SUCCESS"
export const DELETE_SESSION_FAIL = "DELETE_SESSION_FAIL"
