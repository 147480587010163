/* ANSWERS */
export const GET_ANSWERS = "GET_ANSWERS"
export const GET_ANSWERS_BY_ID = "GET_ANSWERS_BY_ID"
export const GET_ANSWERS_SUCCESS = "GET_ANSWERS_SUCCESS"
export const GET_ANSWERS_FAIL = "GET_ANSWERS_FAIL"

/* ANSWERS PROFILE */
export const GET_ANSWER_PROFILE = "GET_ANSWER_PROFILE"
export const GET_ANSWER_PROFILE_SUCCESS = "GET_ANSWER_PROFILE_SUCCESS"
export const GET_ANSWER_PROFILE_FAIL = "GET_ANSWER_PROFILE_FAIL"

/**
 * add answer
 */
export const ADD_NEW_ANSWER = "ADD_NEW_ANSWER"
export const ADD_ANSWER_SUCCESS = "ADD_ANSWER_SUCCESS"
export const ADD_ANSWER_FAIL = "ADD_ANSWER_FAIL"

/**
 * Edit answer
 */
export const UPDATE_ANSWER = "UPDATE_ANSWER"
export const UPDATE_ANSWER_SUCCESS = "UPDATE_ANSWER_SUCCESS"
export const UPDATE_ANSWER_FAIL = "UPDATE_ANSWER_FAIL"

/**
 * Delete answer
 */
export const DELETE_ANSWER = "DELETE_ANSWER"
export const DELETE_ANSWER_SUCCESS = "DELETE_ANSWER_SUCCESS"
export const DELETE_ANSWER_FAIL = "DELETE_ANSWER_FAIL"
