import {
  GET_PROGRAM12_PROFILE,
  GET_PROGRAM12_PROFILE_FAIL,
  GET_PROGRAM12_PROFILE_SUCCESS,
  GET_PROGRAMS12,
  GET_PROGRAMS12_FAIL,
  GET_PROGRAMS12_SUCCESS,
  GET_PROGRAMS12_BY_ID,
  ADD_NEW_PROGRAM12,
  ADD_PROGRAM12_SUCCESS,
  ADD_PROGRAM12_FAIL,
  UPDATE_PROGRAM12,
  UPDATE_PROGRAM12_SUCCESS,
  UPDATE_PROGRAM12_FAIL,
  DELETE_PROGRAM12,
  DELETE_PROGRAM12_SUCCESS,
  DELETE_PROGRAM12_FAIL,
} from "./actionTypes"

export const getPrograms12 = () => ({
  type: GET_PROGRAMS12,
})

export const getPrograms12ById = programs12 => ({
  type: GET_PROGRAMS12_BY_ID,
  payload: programs12,
})

export const getPrograms12Success = programs12 => ({
  type: GET_PROGRAMS12_SUCCESS,
  payload: programs12,
})

export const addNewProgram12 = program12 => ({
  type: ADD_NEW_PROGRAM12,
  payload: program12,
})

export const addProgram12Success = program12 => ({
  type: ADD_PROGRAM12_SUCCESS,
  payload: program12,
})

export const addProgram12Fail = error => ({
  type: ADD_PROGRAM12_FAIL,
  payload: error,
})

export const getPrograms12Fail = error => ({
  type: GET_PROGRAMS12_FAIL,
  payload: error,
})

export const getProgram12Profile = () => ({
  type: GET_PROGRAM12_PROFILE,
})

export const getProgram12ProfileSuccess = rewardProfile => ({
  type: GET_PROGRAM12_PROFILE_SUCCESS,
  payload: rewardProfile,
})

export const getProgram12ProfileFail = error => ({
  type: GET_PROGRAM12_PROFILE_FAIL,
  payload: error,
})

export const updateProgram12 = program12 => ({
  type: UPDATE_PROGRAM12,
  payload: program12,
})

export const updateProgram12Success = program12 => ({
  type: UPDATE_PROGRAM12_SUCCESS,
  payload: program12,
})

export const updateProgram12Fail = error => ({
  type: UPDATE_PROGRAM12_FAIL,
  payload: error,
})

export const deleteProgram12 = program12 => ({
  type: DELETE_PROGRAM12,
  payload: program12,
})

export const deleteProgram12Success = program12 => ({
  type: DELETE_PROGRAM12_SUCCESS,
  payload: program12,
})

export const deleteProgram12Fail = error => ({
  type: DELETE_PROGRAM12_FAIL,
  payload: error,
})
