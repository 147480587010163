import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

const Carddevice = (props) => {
  const { device } = props;

  return (
    <React.Fragment>
      <Col xl="3" sm="6">
        <Card className="text-center">
          <CardBody>
            {/* <UncontrolledDropdown className="dropdown text-end">
              <DropdownToggle className="text-muted font-size-16" tag="a">
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem to="#">Edit</DropdownItem>
                <DropdownItem to="#">Action</DropdownItem>
                <DropdownItem to="#">Remove</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            {console.log(device)}
            {!device.url ? (
              <div className="avatar-xl mx-auto mb-4">
                <span
                  className={
                    "avatar-title bg-light-subtle text-light display-4 m-0 rounded-circle"
                  }
                >
                  <i className="bx bxs-device-circle"></i>
                </span>
              </div>
            ) : (
              <div className="mx-auto mb-0">
                <img
                  className=""
                  style={{maxHeight: '150px', minHeight: '150px'}}
                  src={device.url}
                  alt=""
                />
              </div>
            )}

            {/* <h5 className="font-size-16 mb-1">
              <Link to="#" className="text-dark">
                {device.name}
              </Link>
            </h5> */}
            {/* <p className="text-muted mb-2">{device.designation}</p> */}
          </CardBody>
          <div className="btn-group" role="group">
            <button
              type="button"
              className="btn btn-outline-light text-truncate"
            >
              <i className="uil uil-device me-1"></i> {device.name}
            </button>
            <button
              type="button"
              className="btn btn-outline-light text-truncate"
            >
              <i className="uil uil-envelope-alt me-1"></i> {device.price + " $"}
            </button>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

Carddevice.propTypes = {
  device: PropTypes.object,
};

export default Carddevice;
