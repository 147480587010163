/* CATEGORIES */
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_BY_ID = "GET_CATEGORIES_BY_ID"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

/* CATEGORIES PROFILE */
export const GET_CATEGORY_PROFILE = "GET_CATEGORY_PROFILE"
export const GET_CATEGORY_PROFILE_SUCCESS = "GET_CATEGORY_PROFILE_SUCCESS"
export const GET_CATEGORY_PROFILE_FAIL = "GET_CATEGORY_PROFILE_FAIL"

/**
 * add category
 */
export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY"
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS"
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL"

/**
 * Edit category
 */
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL"

/**
 * Delete category
 */
export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS"
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL"
