import axios from "axios";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  FormFeedback,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";
import Dropzone from "react-dropzone";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Name,
  Email,
  Description,
  Price,
  Id,
  Tags,
  UpdatedAt,
  CreatedAt,
  Projects,
  Img,
} from "./devicelistCol";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getDevices as onGetDevices,
  addNewDevice as onAddNewDevice,
  updateDevice as onUpdateDevice,
  deleteDevice as onDeleteDevice,
} from "../../../store/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const devicesList = () => {
  document.title = "ACUSOLO | Admin & Dashboard";

  const dispatch = useDispatch();
  const [device, setdevice] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (device && device.name) || "",
      description: (device && device.description) || "",
      price: (device && device.price) || "",
      image: (selectedFiles && selectedFiles[0]) || null,

    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Device Name"),
      description: Yup.string().required("Please Enter Device Description"),
      price: Yup.string().required("Please Enter Device Price"),
      image: Yup.mixed().required('Please upload an image'),

    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateDevice = {
          id: device.id,
          name: values.name,
          description: values.description,
          price: values.price,
        };

        const formData = new FormData();

        Object.keys(updateDevice).forEach((key) => {
          formData.append(key, updateDevice[key]);
        });

        if (selectedFiles[0]) {
          formData.append("file", selectedFiles[0]);
        }

        // update device
        dispatch(onUpdateDevice(formData, updateDevice.id));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newDevice = {
          name: values["name"],
          description: values["description"],
          price: values["price"],
        };

        const formData = new FormData();

        Object.keys(newDevice).forEach((key) => {
          formData.append(key, newDevice[key]);
        });
        if (selectedFiles[0]) {
          formData.append("file", selectedFiles[0]);
        }

        // save new device
        dispatch(onAddNewDevice(formData));
        validation.resetForm();
      }
      toggle();
    },
  });

  const devicelistData = createSelector(
    (state) => state.devices,
    (state) => ({
      devices: state.devices,
    })
  );

  // Inside your component
  const { devices } = useSelector(devicelistData);

  const columns = useMemo(
    () => [
      {  
        Header: "No",  
        Cell: ({ row, state }) => {  
          const { pageIndex, pageSize } = state; // Get pageIndex and pageSize from state  
          const rowIndex = row.index + 1 ; // Calculate the row number  
          return <label className="">{rowIndex}</label>;  
        },  
      },
      {
        Header: "Image",
        accessor: "url",
        filterable: false,
        disableFilters: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Img {...cellProps} />;
        },
      },
      {
        Header: "Name",
        filterable: false,
        disableFilters: true,
        accessor: "name",
        Cell: (device) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {device.row.original.name}
              </Link>
            </h5>
            <p className="text-muted mb-0">
              {device.row.original.designation}
            </p>
          </>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        },
      },
      {
        Header: "Price",
        accessor: "price",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Price {...cellProps} />;
        },
      },

      {
        Header: "CreatedAt",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CreatedAt {...cellProps} />;
        },
      },

      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <UpdatedAt {...cellProps} />;
        },
      },
      // {
      //   Header: "Tags",
      //   accessor: "tags",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return <Tags {...cellProps} />;
      //   },
      // },
      // {
      //   Header: "createdAt",
      //   accessor: "createdAt",
      //   filterable: false,
      //   disableFilters: true,
      //   width: "20%",
      //   Cell: (cellProps) => {
      //     return (
      //       <>
      //         <createdAt {...cellProps} />{" "}
      //       </>
      //     );
      //   },
      // },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const deviceData = cellProps.row.original;
                    handleDeviceClick(deviceData);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const deviceData = cellProps.row.original;
                    onClickDelete(deviceData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (devices && !devices.length && !isEmpty(devices)) {
      dispatch(onGetDevices());
      setIsEdit(false);
    }
  }, [dispatch, devices]);

  useEffect(() => {    
    dispatch(onGetDevices());
    setIsEdit(false);
}, []);

  useEffect(() => {
    setdevice(devices);
    setIsEdit(false);
  }, [devices]);

  useEffect(() => {
    if (!isEmpty(devices) && !!isEdit) {
      setdevice(devices);
      setIsEdit(false);
    }
  }, [devices]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleDeviceClick = (arg) => {
    const device = arg;

    setselectedFiles([]);

    setdevice({
      id: device.id,
      name: device.name,
      description: device.description,
      price: device.price,
    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete device
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (devices) => {
    setdevice(devices);
    setDeleteModal(true);
  };

  const handleDeleteDevice = () => {
    dispatch(onDeleteDevice(device.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleDeviceClicks = () => {
    setdevice([]);
    setselectedFiles([]);
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteDevice}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="devices" breadcrumbItem="Device List" /> */}
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Devices List{" "}
                      <span className="text-muted fw-normal ms-2">({devices.length})</span>
                      {/* <span className="text-muted fw-normal ms-2">(500)</span> */}
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <NavLink className="active" href="/devices-list" data-bs-toggle="tooltip"  data-bs-placement="top" id="list">
                            <i className="bx bx-list-ul"></i>
                            <UncontrolledTooltip placement="top" target="list"> List</UncontrolledTooltip>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink  href="/devices-grid"  data-bs-toggle="tooltip" data-bs-placement="top" id="grid"  >
                            <i className="bx bx-grid-alt"></i>
                            <UncontrolledTooltip placement="top" target="grid">
                              Grid
                            </UncontrolledTooltip>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <Link
                        to="#"
                        className="btn btn-primary"
                        onClick={handleDeviceClicks}
                      >
                        <i className="bx bx-plus me-1"></i> Add new
                      </Link>
                    </div>

                    {/* <UncontrolledDropdown>
                      <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a" >
                        <i className="bx bx-dots-horizontal-rounded"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <li><DropdownItem to="#">Action</DropdownItem></li>
                        <li><DropdownItem to="#">Another action</DropdownItem></li>
                        <li><DropdownItem to="#">Something else here</DropdownItem></li>
                      </DropdownMenu>
                    </UncontrolledDropdown> */}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                    <TableContainer
                      columns={columns}
                      data={devices && devices.length > 0 ? devices : []}
                      isGlobalFilter={true}
                      isAddDeviceList={true}
                      customPageSize={10}
                      className="table align-middle datatable dt-responsive table-check nowrap"
                    />
             
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Device" : "Add Device"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          // handleFormSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Insert Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                                <Col className="col-12">
                                  <Label className="form-label">Image</Label>
                                  <Card>
                                    {/* <CardHeader>
                                    <h4 className="card-title">Dropzone</h4>
                                    <p className="card-title-desc">
                                      DropzoneJS is an open source library that
                                      provides drag’n’drop file uploads with
                                      image previews.
                                    </p>
                                  </CardHeader> */}
                                    <CardBody>
                                      {/* <Form> */}
                                      <Dropzone
                                        onDrop={(acceptedFiles) => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick mt-2"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-1">
                                                <i className="display-6 text-muted bx bx-cloud-upload" />
                                                <h6>
                                                  Drop files here or click to
                                                  upload.
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div
                                        className="dropzone-previews mt-3"
                                        id="file-previews"
                                      >
                                        {selectedFiles.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center col-12">
                                                  <Col xs={12}>
                                                    <Col className="col-auto">
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="300"
                                                        className="avatar-lg rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                      />
                                                      <span
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <Link
                                                          to="#"
                                                          className="text-muted font-weight-bold"
                                                        >
                                                          &nbsp;{f.name}
                                                        </Link>

                                                        {/* <p className=""> */}
                                                        <strong>
                                                          &nbsp;
                                                          {"(" +
                                                            f.formattedSize +
                                                            ")"}
                                                        </strong>
                                                        {/* </p> */}
                                                      </span>
                                                    </Col>
                                                  </Col>
                                                  {/* <Col xs={3}>
                                                    <div className="text-center mt-4">
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary "
                                                      >
                                                        Upload
                                                      </button>
                                                    </div>
                                                  </Col> */}
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                      {/* </Form> */}
                                    </CardBody>

                                    { validation.touched.image && validation.errors.image && !selectedFiles[0] ? (
                                    <FormFeedback type="invalid" className="d-block">  
                                    {validation.errors.image}  
                                  </FormFeedback>
                                    ) : null}
                                    
                                  </Card>
                                </Col>
                              </div>

                            <div className="mb-3">
                              <Label className="form-label">Description</Label>
                              <Input
                                name="description"
                                label="Description"
                                type="textarea"
                                placeholder="Insert Description"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description &&
                                  validation.errors.description
                                    ? true
                                    : false
                                }
                                style={{ height: "80px" }}
                              />
                              
                              {validation.touched.description &&
                              validation.errors.description ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.description}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Price</Label>
                              <Input
                                name="price"
                                label="Price"
                                type="text"
                                placeholder="Insert Price"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.price || ""}
                                invalid={
                                  validation.touched.price &&
                                  validation.errors.price
                                    ? true
                                    : false
                                }
                              />
                              
                              {validation.touched.price &&
                              validation.errors.price ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.price}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-device"
                              >
                                {!!isEdit ? "Save" : "Create"}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default devicesList;
