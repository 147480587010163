import { patch, call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PROGRAMS12, GET_PROGRAMS12_BY_ID, GET_PROGRAM12_PROFILE, ADD_NEW_PROGRAM12, DELETE_PROGRAM12, UPDATE_PROGRAM12 } from "./actionTypes"

import {
  getPrograms12Success,
  getPrograms12Fail,
  getProgram12ProfileSuccess,
  getProgram12ProfileFail,
  addProgram12Fail,
  addProgram12Success,
  updateProgram12Success,
  updateProgram12Fail,
  deleteProgram12Success,
  deleteProgram12Fail,
} from "./actions"

//Include Both Helper File with needed methods
import { getPrograms12, getPrograms12ById, getProgram12Profile, addNewProgram12, updateProgram12, deleteProgram12 } from "../../helpers/helper"

function* fetchPrograms12() {
  try {
    const response = yield call(getPrograms12)
    yield put(getPrograms12Success(response))
  } catch (error) {
    yield put(getPrograms12Fail(error))
  }
}

function* fetchPrograms12ById({payload: program12}) {
  try {
    const response = yield call(getPrograms12)
    yield put(getPrograms12Success(response))
  } catch (error) {
    yield put(getPrograms12Fail(error))
  }
}

function* fetchProgram12Profile() {
  try {
    const response = yield call(getProgram12Profile)
    yield put(getProgram12ProfileSuccess(response))
  } catch (error) {
    yield put(getProgram12ProfileFail(error))
  }
}

function* onUpdateProgram12({ payload: program12 }) {
  try {
    // delete program12.id
    const id = program12.get('id');

    const response = yield call(updateProgram12, program12, id)
    yield put(updateProgram12Success(response))
  } catch (error) {
    yield put(updateProgram12Fail(error))
  }
}

function* onDeleteProgram12({ payload: program12 }) {
  try {
    const response = yield call(deleteProgram12, program12)
    yield put(deleteProgram12Success(response))
  } catch (error) {
    yield put(deleteProgram12Fail(error))
  }
}

function* onAddNewProgram12({ payload: program12 }) {

  try {
    const response = yield call(addNewProgram12, program12)

    yield put(addProgram12Success(response))
  } catch (error) {

    yield put(addProgram12Fail(error))
  }
}

function* programs12Saga() {
  yield takeEvery(GET_PROGRAMS12, fetchPrograms12)
  yield takeEvery(GET_PROGRAMS12_BY_ID, fetchPrograms12ById)
  yield takeEvery(GET_PROGRAM12_PROFILE, fetchProgram12Profile)
  yield takeEvery(ADD_NEW_PROGRAM12, onAddNewProgram12)
  yield takeEvery(UPDATE_PROGRAM12, onUpdateProgram12)
  yield takeEvery(DELETE_PROGRAM12, onDeleteProgram12)
}

export default programs12Saga;
