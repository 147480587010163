import {
  GET_POINT12_PROFILE,
  GET_POINT12_PROFILE_FAIL,
  GET_POINT12_PROFILE_SUCCESS,
  GET_POINT12S,
  GET_POINT12S_FAIL,
  GET_POINT12S_SUCCESS,
  GET_POINT12S_BY_ID,
  ADD_NEW_POINT12,
  ADD_POINT12_SUCCESS,
  ADD_POINT12_FAIL,
  UPDATE_POINT12,
  UPDATE_POINT12_SUCCESS,
  UPDATE_POINT12_FAIL,
  DELETE_POINT12,
  DELETE_POINT12_SUCCESS,
  DELETE_POINT12_FAIL,
} from "./actionTypes"

export const getPoints12 = () => ({
  type: GET_POINT12S,
})

export const getPoints12ById = points12 => ({
  type: GET_POINT12S_BY_ID,
  payload: points12,
})

export const getPoints12Success = points12 => ({
  type: GET_POINT12S_SUCCESS,
  payload: points12,
})

export const addNewPoint12 = point12 => ({
  type: ADD_NEW_POINT12,
  payload: point12,
})

export const addPoint12Success = point12 => ({
  type: ADD_POINT12_SUCCESS,
  payload: point12,
})

export const addPoint12Fail = error => ({
  type: ADD_POINT12_FAIL,
  payload: error,
})

export const getPoints12Fail = error => ({
  type: GET_POINT12S_FAIL,
  payload: error,
})

export const getPoint12Profile = () => ({
  type: GET_POINT12_PROFILE,
})

export const getPoint12ProfileSuccess = point12Profile => ({
  type: GET_POINT12_PROFILE_SUCCESS,
  payload: point12Profile,
})

export const getPoint12ProfileFail = error => ({
  type: GET_POINT12_PROFILE_FAIL,
  payload: error,
})

export const updatePoint12 = point12 => ({
  type: UPDATE_POINT12,
  payload: point12,
})

export const updatePoint12Success = point12 => ({
  type: UPDATE_POINT12_SUCCESS,
  payload: point12,
})

export const updatePoint12Fail = error => ({
  type: UPDATE_POINT12_FAIL,
  payload: error,
})

export const deletePoint12 = point12 => ({
  type: DELETE_POINT12,
  payload: point12,
})

export const deletePoint12Success = point12 => ({
  type: DELETE_POINT12_SUCCESS,
  payload: point12,
})

export const deletePoint12Fail = error => ({
  type: DELETE_POINT12_FAIL,
  payload: error,
})
