import { patch, call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SESSION12S, GET_SESSION12S_BY_ID, GET_SESSION12_PROFILE, ADD_NEW_SESSION12, DELETE_SESSION12, UPDATE_SESSION12 } from "./actionTypes"

import {
  getSession12sSuccess,
  getSession12sFail,
  getSession12ProfileSuccess,
  getSession12ProfileFail,
  addSession12Fail,
  addSession12Success,
  updateSession12Success,
  updateSession12Fail,
  deleteSession12Success,
  deleteSession12Fail,
} from "./actions"

//Include Both Helper File with needed methods
import { getSession12s, getSession12sById, getSession12Profile, addNewSession12, updateSession12, deleteSession12 } from "../../helpers/helper"
import session12s from "./reducer"

function* fetchSession12s() {
  try {
    const response = yield call(getSession12s)
    yield put(getSession12sSuccess(response))
  } catch (error) {
    yield put(getSession12sFail(error))
  }
}

function* fetchSession12sById({ payload: id}) {
  try {
    const response = yield call(getSession12sById, id)
    yield put(getSession12sSuccess(response))
  } catch (error) {
    yield put(getSession12sFail(error))
  }
}

function* fetchSession12Profile() {
  try {
    const response = yield call(getSession12Profile)
    yield put(getSession12ProfileSuccess(response))
  } catch (error) {
    yield put(getSession12ProfileFail(error))
  }
}

function* onUpdateSession12({ payload: session12 }) {  
  try {  
    // Extract formData and sid from session12  
    const sid = session12.get('id'); // Replace 'url' with the actual key used in your FormData  

    // Call the updateSession12 function with formData and sid  
    const response = yield call(updateSession12, session12, sid);  

    yield put(updateSession12Success(response));  
  } catch (error) {  
    yield put(updateSession12Fail(error));  
  }  
}  

function* onDeleteSession12({ payload: session12 }) {
  try {
    const response = yield call(deleteSession12, session12)
    yield put(deleteSession12Success(response))
  } catch (error) {
    yield put(deleteSession12Fail(error))
  }
}

function* onAddNewSession12({ payload: session12 }) {

  try {
    const response = yield call(addNewSession12, session12)

    yield put(addSession12Success(response))
  } catch (error) {

    yield put(addSession12Fail(error))
  }
}

function* session12sSaga() {
  yield takeEvery(GET_SESSION12S, fetchSession12s)
  yield takeEvery(GET_SESSION12S_BY_ID, fetchSession12sById)
  yield takeEvery(GET_SESSION12_PROFILE, fetchSession12Profile)
  yield takeEvery(ADD_NEW_SESSION12, onAddNewSession12)
  yield takeEvery(UPDATE_SESSION12, onUpdateSession12)
  yield takeEvery(DELETE_SESSION12, onDeleteSession12)
}

export default session12sSaga;
