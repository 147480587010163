import {
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_FAIL,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_FAIL,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAIL,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAIL,
  GET_QUESTION_PROFILE_SUCCESS,
  GET_QUESTION_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  questions: [],
  questionProfile: {},
  error: {},
}

const questions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: action.payload,
      }

    case GET_QUESTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_QUESTION_SUCCESS:

      return {
        ...state,
        questions: [action.payload, ...state.questions],
      }

    case ADD_QUESTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_QUESTION_PROFILE_SUCCESS:
      return {
        ...state,
        questionProfile: action.payload,
      }

    case UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        questions: state.questions.map(question =>
          question.id.toString() === action.payload.id.toString()
            ? { question, ...action.payload }
            : question
        ),
      }

    case UPDATE_QUESTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        questions: state.questions.filter(
          question => question.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_QUESTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_QUESTION_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default questions
