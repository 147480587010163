import {
  GET_QUESTION_PROFILE,
  GET_QUESTION_PROFILE_FAIL,
  GET_QUESTION_PROFILE_SUCCESS,
  GET_QUESTIONS,
  GET_QUESTIONS_FAIL,
  GET_QUESTIONS_SUCCESS,
  GET_QUESTIONS_BY_ID,
  ADD_NEW_QUESTION,
  ADD_QUESTION_SUCCESS,
  ADD_QUESTION_FAIL,
  UPDATE_QUESTION,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAIL,
  DELETE_QUESTION,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAIL,
} from "./actionTypes"

export const getQuestions = () => ({
  type: GET_QUESTIONS,
})

export const getQuestionsById = questions => ({
  type: GET_QUESTIONS_BY_ID,
  payload: questions,
})

export const getQuestionsSuccess = questions => ({
  type: GET_QUESTIONS_SUCCESS,
  payload: questions,
})

export const addNewQuestion = question => ({
  type: ADD_NEW_QUESTION,
  payload: question,
})

export const addQuestionSuccess = question => ({
  type: ADD_QUESTION_SUCCESS,
  payload: question,
})

export const addQuestionFail = error => ({
  type: ADD_QUESTION_FAIL,
  payload: error,
})

export const getQuestionsFail = error => ({
  type: GET_QUESTIONS_FAIL,
  payload: error,
})

export const getQuestionProfile = () => ({
  type: GET_QUESTION_PROFILE,
})

export const getQuestionProfileSuccess = questionProfile => ({
  type: GET_QUESTION_PROFILE_SUCCESS,
  payload: questionProfile,
})

export const getQuestionProfileFail = error => ({
  type: GET_QUESTION_PROFILE_FAIL,
  payload: error,
})

export const updateQuestion = question => ({
  type: UPDATE_QUESTION,
  payload: question,
})

export const updateQuestionSuccess = question => ({
  type: UPDATE_QUESTION_SUCCESS,
  payload: question,
})

export const updateQuestionFail = error => ({
  type: UPDATE_QUESTION_FAIL,
  payload: error,
})

export const deleteQuestion = question => ({
  type: DELETE_QUESTION,
  payload: question,
})

export const deleteQuestionSuccess = question => ({
  type: DELETE_QUESTION_SUCCESS,
  payload: question,
})

export const deleteQuestionFail = error => ({
  type: DELETE_QUESTION_FAIL,
  payload: error,
})
