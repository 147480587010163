import axios from "axios";
import React, { useEffect, useState, useRef, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import parse from "html-react-parser";
import ReactLoading from "react-loading";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  CardBody,
  Card,
  CardHeader,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";

import Dropzone from "react-dropzone";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Name,
  Email,
  Description,
  Id,
  Category,
  Repetition,
  Tags,
  Point,
  Day,
  UpdatedAt,
  CreatedAt,
  Img,
} from "./program12listCol";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getPrograms12 as onGetPrograms12,
  addNewProgram12 as onAddNewProgram12,
  updateProgram12 as onUpdateProgram12,
  deleteProgram12 as onDeleteProgram12,
} from "../../../store/actions";

import {
  getCategories as onGetCategories,
  getQuestionsById as onGetQuestionsById,
  getQuestions as onGetQuestions,
} from "../../../store/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { duration } from "moment/moment";
import { point } from "leaflet";
import { check } from "prettier";

const programs12List = () => {
  document.title = "ACUSOLO | Admin & Dashboard";

  const dispatch = useDispatch();
  const [program12, setprogram12] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [checkedStates, setCheckedStates] = useState({  
    step1: false, step2: false, step3: false, step4: false, step5: false, step6: false, step7: false, step8: false, step9: false, step10: false, step11: false, step12: false,
  });  
  const [category, setcategory] = useState();
  const [question, setquestion] = useState();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedOriginOptions, setSelectedOriginOptions] = useState({});

  const handleCheckboxChange = (event) => {  
    const { id, checked } = event.target;  
    console.log(id, checked);
    setCheckedStates((prevStates) => ({  
      ...prevStates,  
      [id]: checked,  
    }));  
  };  

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (program12 && program12.name) || "",
      description: (program12 && program12.description) || "",
      image: (selectedFiles && selectedFiles[0]) || null,

    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter 12Program Name"),
      description: Yup.string().required("Please Enter 12Program Description"),
      image: Yup.mixed().required('Please upload an image'),

    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateProgram12 = {
          id: program12.id,
          name: values.name,
          description: values.description,
        };

        const pains = JSON.stringify(checkedStates);  

        const formData = new FormData();

        Object.keys(updateProgram12).forEach((key) => {
          formData.append(key, updateProgram12[key]);
        });

        if (selectedFiles[0]) {
          formData.append("file", selectedFiles[0]);
        }

        formData.append("pains", pains);

        // update device
        dispatch(onUpdateProgram12(formData, updateProgram12.id));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newProgram12 = {
          name: values["name"],
          description: values["description"],
        };

        const pains = JSON.stringify(checkedStates);  

        const formData = new FormData();

        Object.keys(newProgram12).forEach((key) => {
          formData.append(key, newProgram12[key]);
        });

        if (selectedFiles[0]) {
          formData.append("file", selectedFiles[0]);
        }

        formData.append("pains", pains);

        console.log(selectedFiles[0]);
        dispatch(onAddNewProgram12(formData));
        validation.resetForm();
      }
      toggle();
    },
  });

  const program12listData = createSelector(
    (state) => state.programs12,
    (state) => ({
      programs12: state.programs12,
      loading: state.loading,
    })
  );

  // const categorylistData = createSelector(
  //   (state) => state.categories,
  //   (state) => ({
  //     categories: state.categories,
  //   })
  // );

  // Inside your component
  const { programs12, loading } = useSelector(program12listData);
  // const { categories } = useSelector(categorylistData);
  // const { questions } = useSelector(questionlistData);

  const columns = useMemo(
    () => [
      {
        Header: "No",
        Cell: ({ row, state }) => {
          const { pageIndex, pageSize } = state; // Get pageIndex and pageSize from state
          const rowIndex = row.index + 1; // Calculate the row number
          return <label className="">{rowIndex}</label>;
        },
      },
      {
        Header: "Image",
        accessor: "url",
        width: "20%",
        filterable: false,
        disableFilters: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Img {...cellProps} />;
        },
      },
      {
        Header: "Name",
        filterable: false,
        disableFilters: true,
        accessor: "name",
        Cell: (program12) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {program12.row.original.name}
              </Link>
            </h5>
            <p className="text-muted mb-0">
              {program12.row.original.designation}
            </p>
          </>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        },
      },
      {
        Header: "CreatedAt",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CreatedAt {...cellProps} />;
        },
      },

      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <UpdatedAt {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const program12Data = cellProps.row.original;
                    handleProgram12Click(program12Data);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const program12Data = cellProps.row.original;
                    onClickDelete(program12Data);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  // useEffect(() => {
  //   if (questions && !questions.length && !isEmpty(questions)) {
  //     dispatch(onGetQuestions());
  //     setIsEdit(false);
  //   }
  // }, [dispatch, questions]);

  // useEffect(() => {
  //   if (categories && !categories.length && !isEmpty(categories)) {
  //     dispatch(onGetCategories());
  //     setIsEdit(false);
  //   }
  // }, [dispatch, categories]);

  useEffect(() => {
    if (programs12 && !programs12.length && !isEmpty(programs12)) {
      dispatch(onGetPrograms12());
      setIsEdit(false);
    }
  }, [dispatch, programs12]);

  // useEffect(() => {
  //   dispatch(onGetQuestions());
  //   setIsEdit(false);
  // }, []);

  // useEffect(() => {
  //   dispatch(onGetCategories());
  //   setIsEdit(false);
  // }, []);

  useEffect(() => {
    dispatch(onGetPrograms12());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    setprogram12(programs12);
    setIsEdit(false);
  }, [programs12]);

  // useEffect(() => {
  //   setcategory(categories);
  //   // setIsEdit(false);
  // }, [categories]);

  // useEffect(() => {
  //   setquestion(questions);
  //   // setIsEdit(false);
  // }, [questions]);

  useEffect(() => {
    if (!isEmpty(programs12) && !!isEdit) {
      setprogram12(programs12);
      setIsEdit(false);
    }
  }, [programs12]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleProgram12Click = (arg) => {
    const program12 = arg;

    setprogram12({
      id: program12.id,
      name: program12.name,
      description: program12.description,
    });

    if (!program12.pains) {
      setCheckedStates([]);
    } else {
      setCheckedStates(JSON.parse(program12.pains));
    }
    
    // selectedOptions
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete program12
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (programs12) => {
    setprogram12(programs12);
    setDeleteModal(true);
  };

  const handleDeleteProgram12 = () => {
    dispatch(onDeleteProgram12(program12.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleProgram12Clicks = () => {
    setprogram12([]);
    setSelectedOptions([]);
    setCheckedStates([]);
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProgram12}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="programs12" breadcrumbItem="12Program List" /> */}
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      12 Point Programs List{" "}
                      <span className="text-muted fw-normal ms-2">
                        ({programs12.length})
                      </span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link
                        to="#"
                        className="btn btn-primary"
                        onClick={handleProgram12Clicks}
                      >
                        <i className="bx bx-plus me-1"></i> Add new
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={programs12}
                    isGlobalFilter={true}
                    isAdd12ProgramList={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />

                  <Modal isOpen={modal} toggle={toggle} size="mb">
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "View 12Program" : "Create a New 12Program"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                12 Points Program Name
                              </Label>
                              <Input
                                name="name"
                                label="Name"
                                type="text"
                                placeholder="Insert 12Program Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Col className="col-12">
                                <Label className="form-label">Image</Label>
                                <Card>
                       
                                  <CardBody>

                                    <Dropzone
                                      onDrop={(acceptedFiles) => {
                                        handleAcceptedFiles(acceptedFiles);
                                      }}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick mt-2"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />
                                            <div className="mb-1">
                                              <i className="display-6 text-muted bx bx-cloud-upload" />
                                              <h6>
                                                Drop files here or click to
                                                upload.
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                    <div
                                      className="dropzone-previews mt-3"
                                      id="file-previews"
                                    >
                                      {selectedFiles.map((f, i) => {
                                        return (
                                          <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                          >
                                            <div className="p-2">
                                              <Row className="align-items-center col-12">
                                                <Col xs={12}>
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="300"
                                                      className="avatar-lg rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                    <span
                                                      style={{ width: "100%" }}
                                                    >
                                                      <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                      >
                                                        &nbsp;{f.name}
                                                      </Link>

              
                                                      <strong>
                                                        &nbsp;
                                                        {"(" +
                                                          f.formattedSize +
                                                          ")"}
                                                      </strong>
                                                      {/* </p> */}
                                                    </span>
                                                  </Col>
                                                </Col>
                                              </Row>
                                            </div>
                                          </Card>
                                        );
                                      })}
                                    </div>
                                    {/* </Form> */}
                                  </CardBody>
                                  { validation.touched.image && validation.errors.image && !selectedFiles[0] ? (
                                    <FormFeedback type="invalid" className="d-block">  
                                    {validation.errors.image}  
                                  </FormFeedback>
                                    ) : null}
                                </Card>
                              </Col>
                            </div>
                          </Col>
                          {/* <Col xs={6}>
                            
                          </Col> */}
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Description</Label>
                              <Input
                                name="description"
                                label="Description"
                                type="textarea"
                                placeholder="Insert Description"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description &&
                                  validation.errors.description
                                    ? true
                                    : false
                                }
                                style={{ height: "80px" }}
                              />

                              {validation.touched.description &&
                              validation.errors.description ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.description}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Label className="form-label">Painful Point</Label>
                        <Card>
                          <CardBody>
                            <Row>
                              <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="step1"
                                  checked={checkedStates.step1}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="step1"
                                >
                                  &nbsp; Step 01
                                </label>
                              </div>
                              <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="step2"
                                  checked={checkedStates.step2}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="step2"
                                >
                                  &nbsp; Step 02
                                </label>
                              </div>
                              <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="step3"
                                  checked={checkedStates.step3}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="step3"
                                >
                                  &nbsp; Step 03
                                </label>
                              </div>
                              <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="step4"
                                  checked={checkedStates.step4}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="step4"
                                >
                                  &nbsp; Step 04
                                </label>
                              </div>                              
                            </Row>
                            <Row>
                            <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="step5"
                                  checked={checkedStates.step5}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="step5"
                                >
                                  &nbsp; Step 05
                                </label>
                              </div>
                              <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="step6"
                                  checked={checkedStates.step6}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="step6"
                                >
                                  &nbsp; Step 06
                                </label>
                              </div>
                              <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="step7"
                                  checked={checkedStates.step7}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="step7"
                                >
                                  &nbsp; Step 07
                                </label>
                              </div>
                              <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="step8"
                                  checked={checkedStates.step8}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="step8"
                                >
                                  &nbsp; Step 08
                                </label>
                              </div>  
                            </Row>
                            <Row>
                            <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="step9"
                                  checked={checkedStates.step9}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="step9"
                                >
                                  &nbsp; Step 09
                                </label>
                              </div>
                              <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="step10"
                                  checked={checkedStates.step10}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="step10"
                                >
                                  &nbsp; Step 10
                                </label>
                              </div>
                              <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="step11"
                                  checked={checkedStates.step11}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="step11"
                                >
                                  &nbsp; Step 11
                                </label>
                              </div>
                              <div className="col-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="step12"
                                  checked={checkedStates.step12}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="step12"
                                >
                                  &nbsp; Step 12
                                </label>
                              </div>  
                            </Row>                            
                          </CardBody>
                        </Card>
                        <Row>
                          <Col>
                            <div className="text-end">
                              {/* {!!isEdit ?  null:  */}
                              <button
                                type="submit"
                                className="btn btn-primary save-program12"
                              >
                                {!!isEdit ? "Save" : "Create"}
                              </button>
                              {/* } */}
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
          {loading && (
            <div className="loading-container">
              <div className="loading">
                <ReactLoading
                  type="spinningBubbles"
                  color="#007bff"
                  height={100}
                  width={100}
                />
              </div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default programs12List;
