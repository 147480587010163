import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap";
import { map } from "lodash";

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Card
import Cardcategory from "./card-category";

//redux
import { useSelector, useDispatch } from "react-redux";

import { getCategories as onGetCategories } from "../../store/categories/actions";
import { createSelector } from "reselect";
import Pagination from "../../components/Common/Pagination";

const categoriesGrid = () => {

  //meta title
  document.title = "ACUSOLO | Admin & Dashboard";

  const dispatch = useDispatch();

  const categorygridData = createSelector(

    (state) => state.categories,
    (state) => ({
      categories: state.categories,
    })
  );
  // Inside your component
  const { categories } = useSelector(categorygridData);

  const [category, setcategory] = useState([])

    // pagination
    const [currentPage, setCurrentPage] = useState(1);

    const perPageData = categories.length < 8 ? categories.length : 8;
    const indexOfLast = currentPage * perPageData;
    const indexOfFirst = indexOfLast - perPageData;
  
    const currentdata = useMemo(() => categories?.slice(indexOfFirst, indexOfLast), [categories, indexOfFirst, indexOfLast])
  
    // get data
    useEffect(() => {
      dispatch(onGetCategories())
    }, [dispatch]);
  
    useEffect(() => {
      setcategory(currentdata)
    }, [currentdata])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="categories" breadcrumbItem="Category Grid" /> */}
          <Row className="align-items-center">
            <Col md={6}>
              <div className="mb-3">
                <h5 className="card-title">
                  Category Grid{" "}
                  <span className="text-muted fw-normal ms-2">({categories.length})</span>
                </h5>
              </div>
            </Col>

            <Col md={6}>
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <div>
                  <Nav pills>
                    <NavItem>
                      <NavLink
                        href="/categories-list"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        id="list"
                      >
                        <i className="bx bx-list-ul"></i>
                        <UncontrolledTooltip placement="top" target="list">
                          List
                        </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="/categories-grid"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        className="active"
                        id="Grid"
                      >
                        <i className="bx bx-grid-alt"></i>
                        <UncontrolledTooltip placement="top" target="Grid">
                          Grid
                        </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <div>
                  <Link to="#" className="btn btn-light">
                    <i className="bx bx-plus me-1"></i> Add New
                  </Link>
                </div>

                {/* <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn btn-link text-muted py-1 font-size-16 shadow-none"
                    tag="a"
                  >
                    <i className="bx bx-dots-horizontal-rounded"></i>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <li>
                      <DropdownItem to="#">Action</DropdownItem>
                    </li>
                    <li>
                      <DropdownItem to="#">Another action</DropdownItem>
                    </li>
                    <li>
                      <DropdownItem to="#">Something else here</DropdownItem>
                    </li>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
              </div>
            </Col>
          </Row>
          <Row>
            {map(category, (category, key) => (
              <Cardcategory category={category} key={"_category_" + key} />
            ))}
          </Row>
          <Pagination
            perPageData={perPageData}
            data={categories}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            currentData={category}
            className="align-items-center justify-content-between text-center text-sm-start mb-3" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default categoriesGrid;