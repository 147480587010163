import axios from 'axios';  
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Email, Reward, Point, Description, Id, Tags, UpdatedAt, CreatedAt, Projects, Img } from "./rewardlistCol";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getRewards as onGetRewards,
  addNewReward as onAddNewReward,
  updateReward as onUpdateReward,
  deleteReward as onDeleteReward,
} from "../../../store/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { point } from 'leaflet';

const rewardsList = () => {
  document.title = "ACUSOLO | Admin & Dashboard";

  const dispatch = useDispatch();
  const [reward, setreward] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (reward && reward.name) || "",
      description: (reward && reward.description) || "",
      point:  (reward && reward.point) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Reward Name"),
      description: Yup.string().required("Please Enter Reward description"),
      point: Yup.string().required("Please Enter Your Required Point"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateReward = {
          id: reward.id,
          name: values.name,
          description: values.description,
          point: values.point,
        };

        // update reward
        dispatch(onUpdateReward(updateReward));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newReward = {
          name: values["name"],
          description: values["description"],
          point: values["point"],
        };
        // save new reward
        dispatch(onAddNewReward(newReward));
        validation.resetForm();
      }
      toggle();
    },
  });

  const rewardlistData = createSelector(    

    (state) => state.rewards,
    (state) => ({
      rewards: state.rewards,
    })
  );

  // Inside your component
  const { rewards } = useSelector(rewardlistData);

  const columns = useMemo(
    () => [
      {  
        Header: "No",  
        Cell: ({ row, state }) => {  
          const { pageIndex, pageSize } = state; // Get pageIndex and pageSize from state  
          const rowIndex = row.index + 1; // Calculate the row number  
          return <label className="">{rowIndex}</label>;  
        },  
      },
      {
        Header: "Name",
        filterable: false,
        disableFilters: true,
        accessor: "name",
        Cell: (reward) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {reward.row.original.name}
              </Link>
            </h5>
            <p className="text-muted mb-0">{reward.row.original.designation}</p>
          </>
        ),
      },
      {
        Header: "Reward",
        accessor: "description",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        },
      },
      {
        Header: "Point",
        accessor: "point",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Point {...cellProps} />;
        },
      },
      {
        Header: "CreatedAt",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CreatedAt {...cellProps} />;
        },
      },

      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <UpdatedAt {...cellProps} />;
        },
      },
      // {
      //   Header: "Tags",
      //   accessor: "tags",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return <Tags {...cellProps} />;
      //   },
      // },
      // {
      //   Header: "createdAt",
      //   accessor: "createdAt",
      //   filterable: false,
      //   disableFilters: true,
      //   width: "20%",
      //   Cell: (cellProps) => {
      //     return (
      //       <>
      //         <createdAt {...cellProps} />{" "}
      //       </>
      //     );
      //   },
      // },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const rewardData = cellProps.row.original;
                    handleRewardClick(rewardData);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const rewardData = cellProps.row.original;
                    onClickDelete(rewardData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (rewards && !rewards.length && !isEmpty(rewards)) {
      dispatch(onGetRewards());
      setIsEdit(false);
    }
  }, [dispatch, rewards]);

  useEffect(() => {    
    dispatch(onGetRewards());
    setIsEdit(false);
}, []);

  useEffect(() => {
    setreward(rewards);
    setIsEdit(false);
  }, [rewards]);

  useEffect(() => {
    if (!isEmpty(rewards) && !!isEdit) {
      setreward(rewards);
      setIsEdit(false);
    }
  }, [rewards]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleRewardClick = (arg) => {
    const reward = arg;

    setreward({
      id: reward.id,
      name: reward.name,
      description: reward.description,
      point: reward.point,
    });
    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete reward
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (rewards) => {
    setreward(rewards);
    setDeleteModal(true);
  };

  const handleDeleteReward = () => {
    dispatch(onDeleteReward(reward.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleRewardClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteReward}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="rewards" breadcrumbItem="Reward List" /> */}
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Rewards List{" "}
                      <span className="text-muted fw-normal ms-2">({rewards.length})</span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    {/* <div>
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <NavLink className="active" href="/rewards-list" data-bs-toggle="tooltip"  data-bs-placement="top" id="list">
                            <i className="bx bx-list-ul"></i>
                            <UncontrolledTooltip placement="top" target="list"> List</UncontrolledTooltip>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink  href="/rewards-grid"  data-bs-toggle="tooltip" data-bs-placement="top" id="grid"  >
                            <i className="bx bx-grid-alt"></i>
                            <UncontrolledTooltip placement="top" target="grid">
                              Grid
                            </UncontrolledTooltip>
                          </NavLink>
                        </li>
                      </ul>
                    </div> */}
                    <div>
                      <Link to="#" className="btn btn-primary" onClick={handleRewardClicks}>
                        <i className="bx bx-plus me-1"></i> Add new
                      </Link>
                    </div>

                    {/* <UncontrolledDropdown>
                      <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a" >
                        <i className="bx bx-dots-horizontal-rounded"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <li><DropdownItem to="#">Action</DropdownItem></li>
                        <li><DropdownItem to="#">Another action</DropdownItem></li>
                        <li><DropdownItem to="#">Something else here</DropdownItem></li>
                      </DropdownMenu>
                    </UncontrolledDropdown> */}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={rewards}
                    isGlobalFilter={true}
                    isAddRewardList={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Reward" : "Add Reward"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          // handleFormSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Insert Reward Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">
                              Description
                              </Label>
                              <Input
                                name="description"
                                label="Description"
                                type="text"
                                placeholder="Insert Reward Description"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description &&
                                    validation.errors.description
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.description &&
                                validation.errors.description ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.description}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Required Point</Label>
                              <Input
                                name="point"
                                type="text"
                                label="Point"
                                placeholder="Insert Required Point"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.point || ""}
                                invalid={
                                  validation.touched.point &&
                                    validation.errors.point
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.point &&
                                validation.errors.point ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.point}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-reward"
                              >
                                {!!isEdit ? "Save" : "Create"}
                                
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default rewardsList;
