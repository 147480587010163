import axios from 'axios';  
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import { Name, Email, Description, Id, Tags, UpdatedAt, CreatedAt, Projects, Img } from "./questionlistCol";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getQuestions as onGetQuestions,
  addNewQuestion as onAddNewQuestion,
  updateQuestion as onUpdateQuestion,
  deleteQuestion as onDeleteQuestion,
} from "../../../store/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const questionsList = () => {
  document.title = "ACUSOLO | Admin & Dashboard";

  const dispatch = useDispatch();
  const [question, setquestion] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (question && question.name) || "",
      description: (question && question.description) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      description: Yup.string().required("Please Enter Your Description"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateQuestion = {
          id: question.id,
          name: values.name,
          description: values.description,
        };

        // update question
        dispatch(onUpdateQuestion(updateQuestion));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newQuestion = {
          name: values["name"],
          description: values["description"],
        };
        // save new question
        dispatch(onAddNewQuestion(newQuestion));
        validation.resetForm();
      }
      toggle();
    },
  });

  const handleFormSubmit = async (values) => {
    try {  
      const url = 'http://127.0.0.1:3000/';  
      
      // Using axios  
      const response = await axios.post(url, values);  
  
      if (response.status === 200) {  
        // Handle successful submission  
        console.log('Form submitted successfully');  
        // You can add any additional logic here, like showing a success message or redirecting  
      } else {  
        // Handle error  
        console.error('Form submission failed');  
        // You can add error handling logic here  
      }  
    } catch (error) {  
      console.error('Error submitting form:', error);  
      // Handle any network or other errors  
    }  
  };

  const questionlistData = createSelector(    

    (state) => state.questions,
    (state) => ({
      questions: state.questions,
    })
  );

  // Inside your component
  const { questions } = useSelector(questionlistData);

  const columns = useMemo(
    () => [
      {  
        Header: "No",  
        Cell: ({ row, state }) => {  
          const { pageIndex, pageSize } = state; // Get pageIndex and pageSize from state  
          const rowIndex = row.index + 1 ; // Calculate the row number  
          return <label className="">{rowIndex}</label>;  
        },  
      },
      {
        Header: "Name",
        filterable: false,
        disableFilters: true,
        accessor: "name",
        Cell: (question) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {question.row.original.name}
              </Link>
            </h5>
            <p className="text-muted mb-0">{question.row.original.designation}</p>
          </>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        },
      },
      {
        Header: "CreatedAt",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CreatedAt {...cellProps} />;
        },
      },
      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <UpdatedAt {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const questionData = cellProps.row.original;
                    handleQuestionClick(questionData);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const questionData = cellProps.row.original;
                    onClickDelete(questionData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (questions && !questions.length && !isEmpty(questions)) {
      dispatch(onGetQuestions());
      setIsEdit(false);
    }
  }, [dispatch, questions]);

  useEffect(() => {    
    dispatch(onGetQuestions());
    setIsEdit(false);
}, []);

  useEffect(() => {
    setquestion(questions);
    setIsEdit(false);
  }, [questions]);

  useEffect(() => {
    if (!isEmpty(questions) && !!isEdit) {
      setquestion(questions);
      setIsEdit(false);
    }
  }, [questions]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleQuestionClick = (arg) => {
    const question = arg;

    setquestion({
      id: question.id,
      name: question.name,
      description: question.description,
    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete question
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (questions) => {
    setquestion(questions);
    setDeleteModal(true);
  };

  const handleDeleteQuestion = () => {
    dispatch(onDeleteQuestion(question.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleQuestionClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteQuestion}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Questions List{" "}
                      <span className="text-muted fw-normal ms-2">({questions.length})</span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <Link to="#" className="btn btn-primary" onClick={handleQuestionClicks}>
                        <i className="bx bx-plus me-1"></i> Add new
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={questions}
                    isGlobalFilter={true}
                    isAddQuestionList={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Question" : "Add Question"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          // handleFormSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Insert Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                    validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                                validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">
                                Description
                              </Label>
                              <Input
                                name="description"
                                label="Description"
                                type="text"
                                placeholder="Insert Description"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description &&
                                    validation.errors.description
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.description &&
                                validation.errors.description ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.description}
                                </FormFeedback>
                              ) : null}
                            </div>
                            
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-question"
                              >
                                {!!isEdit ? "Save" : "Create"}
                                
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default questionsList;
