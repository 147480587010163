import axios from "axios";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  Card,
  CardBody,
  FormFeedback,
  UncontrolledTooltip,
  NavLink,
} from "reactstrap";
import Dropzone from "react-dropzone";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Name,
  Email,
  Description,
  Id,
  Tags,
  UpdatedAt,
  CreatedAt,
  Projects,
  Img,
  Icon,
} from "./categorylistCol";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getCategories as onGetCategories,
  addNewCategory as onAddNewCategory,
  updateCategory as onUpdateCategory,
  deleteCategory as onDeleteCategory,
} from "../../../store/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const categoriesList = () => {
  document.title = "ACUSOLO | Admin & Dashboard";

  const dispatch = useDispatch();
  const [category, setcategory] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedIconFiles, setselectedIconFiles] = useState([]);
  const [selectedImgFiles, setselectedImgFiles] = useState([]);


  function handleAcceptedIconFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    

    setselectedIconFiles(files);
  }

  function handleAcceptedImgFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedImgFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (category && category.name) || "",
      description: (category && category.description) || "",
      iconImage: (selectedIconFiles && selectedIconFiles[0]) || null,
      blogImage: (selectedImgFiles && selectedImgFiles[0]) || null,

    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      description: Yup.string().required("Please Enter Your Description"),
      // iconImage: Yup.array().min(1, 'Please upload at least one file').required('File is required'), 
      iconImage: Yup.mixed().required('Please upload an icon image'),
      blogImage: Yup.mixed().required('Please upload an blog image'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateCategory = {
          id: category.id,
          name: values.name,
          description: values.description,
        };

        const formData = new FormData();

        Object.keys(updateCategory).forEach((key) => {
          formData.append(key, updateCategory[key]);
        });

        if (selectedIconFiles[0]) {
          formData.append("iconfiles", selectedIconFiles[0]);
          console.log(selectedIconFiles[0].size);
        }

        if (selectedImgFiles[0]) {
          formData.append("imgfiles", selectedImgFiles[0]);
          console.log(selectedImgFiles[0].size);

        }

        // update category
        dispatch(onUpdateCategory(formData, updateCategory.id));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newCategory = {
          name: values["name"],
          description: values["description"],
        };

        const formData = new FormData();

        Object.keys(newCategory).forEach((key) => {
          formData.append(key, newCategory[key]);
        });

        if (selectedIconFiles[0]) {
          formData.append("iconfiles", selectedIconFiles[0]);
          console.log(selectedIconFiles[0].size);
        }

        if (selectedImgFiles[0]) {
          formData.append("imgfiles", selectedImgFiles[0]);
          console.log(selectedImgFiles[0].size);

        }

        // save new category
        dispatch(onAddNewCategory(formData));
        validation.resetForm();
      }
      toggle();
    },
  });

  const categorylistData = createSelector(
    (state) => state.categories,
    (state) => ({
      categories: state.categories,
    })
  );

  // Inside your component
  const { categories } = useSelector(categorylistData);

  const columns = useMemo(
    () => [
      {  
        Header: "No",  
        Cell: ({ row, state }) => {  
          const { pageIndex, pageSize } = state; // Get pageIndex and pageSize from state  
          const rowIndex = row.index + 1; // Calculate the row number  
          return <label className="">{rowIndex}</label>;  
        },  
      },
      {
        Header: "Icon",
        accessor: "iconUrl",
        filterable: false,
        disableFilters: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Icon {...cellProps} />;
        },
      },
      {
        Header: "Blog image",
        accessor: "imgUrl",
        filterable: false,
        disableFilters: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Img {...cellProps} />;
        },
      },
      {
        Header: "Name",
        filterable: false,
        disableFilters: true,
        accessor: "name",
        Cell: (category) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {category.row.original.name}
              </Link>
            </h5>
            <p className="text-muted mb-0">
              {category.row.original.designation}
            </p>
          </>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        },
      },

      {
        Header: "CreatedAt",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CreatedAt {...cellProps} />;
        },
      },

      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <UpdatedAt {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const categoryData = cellProps.row.original;
                    handleCategoryClick(categoryData);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const categoryData = cellProps.row.original;
                    onClickDelete(categoryData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (categories && !categories.length && !isEmpty(categories)) {
      dispatch(onGetCategories());
      setIsEdit(false);
    }
  }, [dispatch, categories]);

  useEffect(() => {    
    dispatch(onGetCategories());
    setIsEdit(false);
}, []);

  useEffect(() => {
    setcategory(categories);
    setIsEdit(false);
  }, [categories]);

  useEffect(() => {
    if (!isEmpty(categories) && !!isEdit) {
      setcategory(categories);
      setIsEdit(false);
    }
  }, [categories]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleCategoryClick = (arg) => {
    const category = arg;
    setselectedImgFiles([]);
    setselectedIconFiles([]);

    setcategory({
      id: category.id,
      name: category.name,
      description: category.description,
    });
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete category
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (categories) => {
    setcategory(categories);
    setDeleteModal(true);
  };

  const handleDeleteCategory = () => {
    dispatch(onDeleteCategory(category.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleCategoryClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Categories List{" "}
                      <span className="text-muted fw-normal ms-2">({categories.length})</span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    <div>
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <NavLink className="active" href="/categories-list" data-bs-toggle="tooltip"  data-bs-placement="top" id="list">
                            <i className="bx bx-list-ul"></i>
                            <UncontrolledTooltip placement="top" target="list"> List</UncontrolledTooltip>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink  href="/categories-grid"  data-bs-toggle="tooltip" data-bs-placement="top" id="grid"  >
                            <i className="bx bx-grid-alt"></i>
                            <UncontrolledTooltip placement="top" target="grid">
                              Grid
                            </UncontrolledTooltip>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <Link
                        to="#"
                        className="btn btn-primary"
                        onClick={handleCategoryClicks}
                      >
                        <i className="bx bx-plus me-1"></i> Add new
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                    <TableContainer
                      columns={columns}
                      data={categories && categories.length > 0 ? categories : []}
                      isGlobalFilter={true}
                      isAddCategoryList={true}
                      customPageSize={10}
                      className="table align-middle datatable dt-responsive table-check nowrap"
                    />
             
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Category" : "Add Category"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="name"
                                type="text"
                                placeholder="Insert Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                                <Col className="col-12">
                                  <Label className="form-label">Icon Image</Label>
                                  <Card>
                                    <CardBody>
                                      <Dropzone
                                        onDrop={(acceptedIconFiles) => {
                                          handleAcceptedIconFiles(acceptedIconFiles);
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick mt-2"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-1">
                                                <i className="display-6 text-muted bx bx-cloud-upload" />
                                                <h6>
                                                  Drop files here or click to
                                                  upload.
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div
                                        className="dropzone-previews mt-3"
                                        id="file-previews"
                                      >
                                        {selectedIconFiles.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center col-12">
                                                  <Col xs={12}>
                                                    <Col className="col-auto">
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="300"
                                                        className="avatar-lg rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                      />
                                                      <span
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <Link
                                                          to="#"
                                                          className="text-muted font-weight-bold"
                                                        >
                                                          &nbsp;{f.name}
                                                        </Link>
                                                        <strong>
                                                          &nbsp;
                                                          {"(" +
                                                            f.formattedSize +
                                                            ")"}
                                                        </strong>
                                                      </span>
                                                    </Col>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                    </CardBody>

                                    { validation.touched.iconImage && validation.errors.iconImage && !selectedIconFiles[0] ? (
                                    <FormFeedback type="invalid" className="d-block">  
                                    {validation.errors.iconImage}  
                                  </FormFeedback>
                                    ) : null}

                                  </Card>
                                </Col>
                              </div>
                            
                              <div className="mb-3">
                                <Col className="col-12">
                                  <Label className="form-label">Blog Image</Label>
                                  <Card>
                                    <CardBody>
                                      <Dropzone
                                        onDrop={(acceptedImgFiles) => {
                                          handleAcceptedImgFiles(acceptedImgFiles);
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick mt-2"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-1">
                                                <i className="display-6 text-muted bx bx-cloud-upload" />
                                                <h6>
                                                  Drop files here or click to
                                                  upload.
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div
                                        className="dropzone-previews mt-3"
                                        id="file-previews"
                                      >
                                        {selectedImgFiles.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center col-12">
                                                  <Col xs={12}>
                                                    <Col className="col-auto">
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="300"
                                                        className="avatar-lg rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                      />
                                                      <span
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <Link
                                                          to="#"
                                                          className="text-muted font-weight-bold"
                                                        >
                                                          &nbsp;{f.name}
                                                        </Link>

                                                        {/* <p className=""> */}
                                                        <strong>
                                                          &nbsp;
                                                          {"(" +
                                                            f.formattedSize +
                                                            ")"}
                                                        </strong>
                                                        {/* </p> */}
                                                      </span>
                                                    </Col>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                    </CardBody>

                                    { validation.touched.blogImage && validation.errors.blogImage && !selectedImgFiles[0] ? (
                                    <FormFeedback type="invalid" className="d-block">  
                                    {validation.errors.blogImage}  
                                  </FormFeedback>
                                    ) : null}

                                  </Card>
                                </Col>
                              </div>

                            <div className="mb-3">
                              <Label className="form-label">Description</Label>
                              <Input
                                name="description"
                                label="Description"
                                type="textarea"
                                placeholder="Insert Description"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description &&
                                  validation.errors.description
                                    ? true
                                    : false
                                }
                                style={{ height: "80px" }}
                              />
                              
                              {validation.touched.description &&
                              validation.errors.description ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.description}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-category"
                              >
                                {!!isEdit ? "Save" : "Create"}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default categoriesList;
