/* PROGRAMS */
export const GET_PROGRAMS = "GET_PROGRAMS"
export const GET_PROGRAMS_BY_ID = "GET_PROGRAMS_BY_ID"
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS"
export const GET_PROGRAMS_FAIL = "GET_PROGRAMS_FAIL"

/* PROGRAMS PROFILE */
export const GET_PROGRAM_PROFILE = "GET_PROGRAM_PROFILE"
export const GET_PROGRAM_PROFILE_SUCCESS = "GET_PROGRAM_PROFILE_SUCCESS"
export const GET_PROGRAM_PROFILE_FAIL = "GET_PROGRAM_PROFILE_FAIL"

/**
 * add program
 */
export const ADD_NEW_PROGRAM = "ADD_NEW_PROGRAM"
export const ADD_NEW_PROGRAM_ANSWER = "ADD_NEW_PROGRAM_ANSWER"
export const ADD_PROGRAM_ANSWER_SUCCESS = "ADD_PROGRAM_ANSWER_SUCCESS"
export const ADD_PROGRAM_SUCCESS = "ADD_PROGRAM_SUCCESS"
export const ADD_PROGRAM_FAIL = "ADD_PROGRAM_FAIL"

/**
 * Edit program
 */
export const UPDATE_PROGRAM = "UPDATE_PROGRAM"
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS"
export const UPDATE_PROGRAM_FAIL = "UPDATE_PROGRAM_FAIL"

/**
 * Delete program
 */
export const DELETE_PROGRAM = "DELETE_PROGRAM"
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS"
export const DELETE_PROGRAM_FAIL = "DELETE_PROGRAM_FAIL"
