import {
  GET_PROGRAMS12_SUCCESS,
  GET_PROGRAMS12_FAIL,
  ADD_PROGRAM12_SUCCESS,
  ADD_PROGRAM12_FAIL,
  UPDATE_PROGRAM12_SUCCESS,
  UPDATE_PROGRAM12_FAIL,
  DELETE_PROGRAM12_SUCCESS,
  DELETE_PROGRAM12_FAIL,
  GET_PROGRAM12_PROFILE_SUCCESS,
  GET_PROGRAM12_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  programs12: [],
  rewardProfile: {},
  error: {},
}

const programs12 = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROGRAMS12_SUCCESS:
      return {
        ...state,
        programs12: action.payload,
      }

    case GET_PROGRAMS12_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PROGRAM12_SUCCESS:

      return {
        ...state,
        programs12: [action.payload, ...state.programs12],
      }

    case ADD_PROGRAM12_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROGRAM12_PROFILE_SUCCESS:
      return {
        ...state,
        rewardProfile: action.payload,
      }

    case UPDATE_PROGRAM12_SUCCESS:
      return {
        ...state,
        programs12: state.programs12.map(program12 =>
          program12.id.toString() === action.payload.id.toString()
            ? { program12, ...action.payload }
            : program12
        ),
      }

    case UPDATE_PROGRAM12_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PROGRAM12_SUCCESS:
      return {
        ...state,
        programs12: state.programs12.filter(
          program12 => program12.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_PROGRAM12_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROGRAM12_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default programs12
