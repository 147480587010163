import axios from "axios";
import React, { useEffect, useState, useRef, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import parse from "html-react-parser";
import ReactLoading from "react-loading";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  CardBody,
  Card,
  CardHeader,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";

import Dropzone from "react-dropzone";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Name,
  Email,
  Description,
  Id,
  Category,
  Repetition,
  Tags,
  Point,
  Day,
  UpdatedAt,
  CreatedAt,
  Img,
} from "./programlistCol";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getPrograms as onGetPrograms,
  addNewProgram as onAddNewProgram,
  addNewProgramAnswer as onAddNewProgramAnswer,
  updateProgram as onUpdateProgram,
  deleteProgram as onDeleteProgram,
} from "../../../store/actions";

import {
  getCategories as onGetCategories,
  getQuestionsById as onGetQuestionsById,
  getQuestions as onGetQuestions,
} from "../../../store/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { duration } from "moment/moment";
import { point } from "leaflet";

const programsList = () => {
  document.title = "ACUSOLO | Admin & Dashboard";

  const dispatch = useDispatch();
  const [program, setprogram] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [category, setcategory] = useState();
  const [question, setquestion] = useState();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedOriginOptions, setSelectedOriginOptions] = useState({});

  const handleSelectChange = (selected, questionId) => {
    // const updatedItems = selected.map((item, index) => {
    //   item.label = ReactDOMServer.renderToStaticMarkup(item.label);
    //   return item; // Return the modified item
    // });

    setSelectedOptions((prev) => ({
      ...prev,
      [questionId]: selected, // Update the selected options for the specific question ID
    }));
  };

  const animatedComponents = makeAnimated();

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (program && program.name) || "",
      // category: (program && program.category) || "",
      cid: (program && program.cid) || "",
      day: (program && program.day) || "",
      duration: (program && program.duration) || "",
      point: (program && program.point) || "",
      repetition: (program && program.repetition) || "",
      // question: (program && program.question) || "",
      // answer: (program && program.answer) || "",
      description: (program && program.description) || "",
      image: (selectedFiles && selectedFiles[0]) || null,

    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Program Name"),
      // category: Yup.string().required("Please Enter Program Category"),
      cid: Yup.string().required("Please Enter Program Category"),
      day: Yup.string().required("Please Enter Program Day"),
      duration: Yup.string().required("Please Enter Program Session Duration"),
      point: Yup.string().required("Please Enter Program Reward Point"),
      repetition: Yup.string().required("Please Enter Program Repetition"),
      // question: Yup.string().required("Please Enter Program Question"),
      // answer: Yup.string().required("Please Enter Program Answer"),
      description: Yup.string().required("Please Enter Program Description"),
      image: Yup.mixed().required('Please upload an image'),

    }),
    onSubmit: (values) => {
      const getSelectedValuesList = () => {
        return Object.entries(selectedOptions).flatMap(
          ([questionId, options]) =>
            options.map((option) => ({
              pid: " ",
              qid: questionId,
              aid: option.value,
              pName: values["name"],
            }))
        );
      };
      const selectedValuesList = getSelectedValuesList();

      const getSelectedOriginalValuesList = () => {
        return Object.entries(selectedOriginOptions).flatMap(
          ([questionId, options]) =>
            options.map((option) => ({
              pid: " ",
              qid: questionId,
              aid: option.value,
              pName: values["name"],
            }))
        );
      };
      const selectedOriginalValuesList = getSelectedOriginalValuesList();

      if (isEdit) {
        const updateProgram = {
          id: program.id,
          name: values.name,
          description: values.description,
          day: values.day,
          duration: values.duration,
          point: values.point,
          cid: values.cid,
          repetition: values.repetition,
        };

        // update program
        dispatch(onAddNewProgramAnswer(selectedValuesList));

        setTimeout(() => {
          dispatch(onUpdateProgram(updateProgram));
        }, 1000);
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newProgram = {
          name: values["name"],
          description: values["description"],
          day: values["day"],
          duration: values["duration"],
          point: values["point"],
          cid: values["cid"],
          repetition: values["repetition"],
        };

        const formData = new FormData();

        Object.keys(newProgram).forEach((key) => {
          formData.append(key, newProgram[key]);
        });

        if (selectedFiles[0]) {
          formData.append("file", selectedFiles[0]);
        }

        dispatch(onAddNewProgram(formData));
        dispatch(onAddNewProgramAnswer(selectedValuesList));
        validation.resetForm();
      }
      toggle();
    },
  });

  const options = [];

  for (let i = 2; i <= 20; i++) {
    options.push(
      <option key={i} value={i}>
        {i} days
      </option>
    );
  }

  const questionlistData = createSelector(
    (state) => state.questions,
    (state) => ({
      questions: state.questions,
    })
  );

  const programlistData = createSelector(
    (state) => state.programs,
    (state) => ({
      programs: state.programs,
      loading: state.loading,
    })
  );

  const categorylistData = createSelector(
    (state) => state.categories,
    (state) => ({
      categories: state.categories,
    })
  );

  // Inside your component
  const { programs, loading } = useSelector(programlistData);
  const { categories } = useSelector(categorylistData);
  const { questions } = useSelector(questionlistData);

  const columns = useMemo(
    () => [
      {
        Header: "No",
        Cell: ({ row, state }) => {
          const { pageIndex, pageSize } = state; // Get pageIndex and pageSize from state
          const rowIndex = row.index + 1; // Calculate the row number
          return <label className="">{rowIndex}</label>;
        },
      },
      {
        Header: "Img",
        accessor: "url",
        width: "20%",
        filterable: false,
        disableFilters: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Img {...cellProps} />;
        },
      },
      {
        Header: "Name",
        filterable: false,
        disableFilters: true,
        accessor: "name",
        Cell: (program) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {program.row.original.name}
              </Link>
            </h5>
            <p className="text-muted mb-0">
              {program.row.original.designation}
            </p>
          </>
        ),
      },
      {
        Header: "Days",
        accessor: "day",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Day {...cellProps} />;
        },
      },
      {
        Header: "Category",
        accessor: "category",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Category {...cellProps} />;
        },
      },
      {
        Header: "Duration per Day",
        accessor: "duration",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        },
      },
      {
        Header: "Points",
        accessor: "point",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Point {...cellProps} />;
        },
      },
      {
        Header: "Repetitions",
        accessor: "repetition",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Repetition {...cellProps} />;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        },
      },
      {
        Header: "CreatedAt",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CreatedAt {...cellProps} />;
        },
      },

      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <UpdatedAt {...cellProps} />;
        },
      },
      // {
      //   Header: "Tags",
      //   accessor: "tags",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return <Tags {...cellProps} />;
      //   },
      // },
      // {
      //   Header: "createdAt",
      //   accessor: "createdAt",
      //   filterable: false,
      //   disableFilters: true,
      //   width: "20%",
      //   Cell: (cellProps) => {
      //     return (
      //       <>
      //         <createdAt {...cellProps} />{" "}
      //       </>
      //     );
      //   },
      // },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const programData = cellProps.row.original;
                    handleProgramClick(programData);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const programData = cellProps.row.original;
                    onClickDelete(programData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (questions && !questions.length && !isEmpty(questions)) {
      dispatch(onGetQuestions());
      setIsEdit(false);
    }
  }, [dispatch, questions]);

  useEffect(() => {
    if (categories && !categories.length && !isEmpty(categories)) {
      dispatch(onGetCategories());
      setIsEdit(false);
    }
  }, [dispatch, categories]);

  useEffect(() => {
    if (programs && !programs.length && !isEmpty(programs)) {
      dispatch(onGetPrograms());
      setIsEdit(false);
    }
  }, [dispatch, programs]);

  useEffect(() => {
    dispatch(onGetQuestions());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    dispatch(onGetCategories());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    dispatch(onGetPrograms());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    setprogram(programs);
    setIsEdit(false);
  }, [programs]);

  useEffect(() => {
    setcategory(categories);
    // setIsEdit(false);
  }, [categories]);

  useEffect(() => {
    setquestion(questions);
    // setIsEdit(false);
  }, [questions]);

  useEffect(() => {
    if (!isEmpty(programs) && !!isEdit) {
      setprogram(programs);
      setIsEdit(false);
    }
  }, [programs]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleProgramClick = (arg) => {
    const program = arg;

    console.log({ program });
    setprogram({
      id: program.id,
      name: program.name,
      description: program.description,
      category: program.category,
      day: program.day,
      duration: program.duration,
      cid: program.cid,
      point: program.point,
      repetition: program.repetition,
    });

    setSelectedOptions(program.qa);
    setSelectedOriginOptions(program.qa);
    // selectedOptions
    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete program
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (programs) => {
    setprogram(programs);
    setDeleteModal(true);
  };

  const handleDeleteProgram = () => {
    dispatch(onDeleteProgram(program.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleProgramClicks = () => {
    setprogram([]);
    setSelectedOptions([]);
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProgram}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="programs" breadcrumbItem="Program List" /> */}
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Programs List{" "}
                      <span className="text-muted fw-normal ms-2">
                        ({programs.length})
                      </span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    {/* <div>
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <NavLink className="active" href="/programs-list" data-bs-toggle="tooltip"  data-bs-placement="top" id="list">
                            <i className="bx bx-list-ul"></i>
                            <UncontrolledTooltip placement="top" target="list"> List</UncontrolledTooltip>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink  href="/programs-grid"  data-bs-toggle="tooltip" data-bs-placement="top" id="grid"  >
                            <i className="bx bx-grid-alt"></i>
                            <UncontrolledTooltip placement="top" target="grid">
                              Grid
                            </UncontrolledTooltip>
                          </NavLink>
                        </li>
                      </ul>
                    </div> */}
                    <div>
                      <Link
                        to="#"
                        className="btn btn-primary"
                        onClick={handleProgramClicks}
                      >
                        <i className="bx bx-plus me-1"></i> Add new
                      </Link>
                    </div>

                    {/* <UncontrolledDropdown>
                      <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a" >
                        <i className="bx bx-dots-horizontal-rounded"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <li><DropdownItem to="#">Action</DropdownItem></li>
                        <li><DropdownItem to="#">Another action</DropdownItem></li>
                        <li><DropdownItem to="#">Something else here</DropdownItem></li>
                      </DropdownMenu>
                    </UncontrolledDropdown> */}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={programs}
                    isGlobalFilter={true}
                    isAddProgramList={true}
                    customPageSize={10}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />

                  <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "View Program" : "Create a New Program"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          // handleFormSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={6}>
                            <div className="col-12 mb-3">
                              <Label className="form-label">
                                Program Category
                              </Label>
                              <Input
                                type="select"
                                name="cid"
                                label="Cid"
                                placeholder="Select Category..."
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                // value={!!isEdit ? validation.values.cid || "" : validation.values.cid || ""}
                                value={validation.values.cid || ""}
                                invalid={
                                  validation.touched.cid &&
                                  validation.errors.cid
                                    ? true
                                    : false
                                }
                              >
                                <option value="00000000-0000-0000-0000-000000000000"></option>
                                {categories.map((category) => (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                ))}
                              </Input>
                              {validation.touched.cid &&
                              validation.errors.cid ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.cid}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Program Name</Label>
                              <Input
                                name="name"
                                label="Name"
                                type="text"
                                placeholder="Insert Program Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <div className="col-12 mb-3">
                              <Label className="form-label">
                                Number of Days
                              </Label>
                              <Input
                                type="select"
                                label="Day"
                                name="day"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.day || ""}
                                invalid={
                                  validation.touched.day &&
                                  validation.errors.day
                                    ? true
                                    : false
                                }
                              >
                                <option value="default">Select Days</option>
                                <option value="1"> 1 day </option>
                                {options}
                                {/* <option value="1">1 days</option>
                                <option value="5">5 days</option>
                                <option value="10">10 days</option>
                                <option value="20">20 days</option>
                                <option value="30">30 days</option> */}
                              </Input>
                              {validation.touched.day &&
                              validation.errors.day ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.day}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Session Duration(mins)
                              </Label>
                              <Input
                                name="duration"
                                label="Duration"
                                type="text"
                                placeholder="Insert Duration"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.duration || ""}
                                invalid={
                                  validation.touched.duration &&
                                  validation.errors.duration
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.duration &&
                              validation.errors.duration ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.duration}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Col className="col-12">
                                <Label className="form-label">Image</Label>
                                <Card>
                                  <CardBody>
                                    <Dropzone
                                      onDrop={(acceptedFiles) => {
                                        handleAcceptedFiles(acceptedFiles);
                                      }}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                          <div
                                            className="dz-message needsclick mt-2"
                                            {...getRootProps()}
                                          >
                                            <input {...getInputProps()} />
                                            <div className="mb-1">
                                              <i className="display-6 text-muted bx bx-cloud-upload" />
                                              <h6>
                                                Drop files here or click to
                                                upload.
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Dropzone>
                                    <div
                                      className="dropzone-previews mt-3"
                                      id="file-previews"
                                    >
                                      {selectedFiles.map((f, i) => {
                                        return (
                                          <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                          >
                                            <div className="p-2">
                                              <Row className="align-items-center col-12">
                                                <Col xs={12}>
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="300"
                                                      className="avatar-lg rounded bg-light"
                                                      alt={f.name}
                                                      src={f.preview}
                                                    />
                                                    <span
                                                      style={{ width: "100%" }}
                                                    >
                                                      <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                      >
                                                        &nbsp;{f.name}
                                                      </Link>

                                                      {/* <p className=""> */}
                                                      <strong>
                                                        &nbsp;
                                                        {"(" +
                                                          f.formattedSize +
                                                          ")"}
                                                      </strong>
                                                      {/* </p> */}
                                                    </span>
                                                  </Col>
                                                </Col>
                                              </Row>
                                            </div>
                                          </Card>
                                        );
                                      })}
                                    </div>
                                  </CardBody>

                                  { validation.touched.image && validation.errors.image && !selectedFiles[0] ? (
                                    <FormFeedback type="invalid" className="d-block">  
                                    {validation.errors.image}  
                                  </FormFeedback>
                                    ) : null}

                                </Card>
                              </Col>
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Description</Label>
                              <Input
                                name="description"
                                label="Description"
                                type="textarea"
                                placeholder="Insert Description"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description &&
                                  validation.errors.description
                                    ? true
                                    : false
                                }
                                style={{ height: "200px" }}
                              />

                              {validation.touched.description &&
                              validation.errors.description ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.description}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Reward Points
                              </Label>
                              <Input
                                name="point"
                                label="Point"
                                type="text"
                                placeholder="Insert Reward Point"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.point || ""}
                                invalid={
                                  validation.touched.point &&
                                  validation.errors.point
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.point &&
                              validation.errors.point ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.point}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label className="form-label">Repetitions</Label>
                              <Input
                                name="repetition"
                                label="Repetition"
                                type="text"
                                placeholder="Insert Repetitions"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.repetition || ""}
                                invalid={
                                  validation.touched.repetition &&
                                  validation.errors.repetition
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.repetition &&
                              validation.errors.repetition ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.repetition}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Label className="form-label">Question & Answer</Label>
                        <Card>
                          <CardBody>
                            {questions.map((question) => {
                              return (
                                <div key={question.id}>
                                  <Row>
                                    <Col xs={4}>
                                      <div className="col-12 mb-3">
                                        <Input
                                          name="question"
                                          label="Question"
                                          type="text"
                                          placeholder=""
                                          disabled
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            "Question : " + question.name || ""
                                          }
                                        />
                                      </div>
                                    </Col>
                                    <Col xs={8}>
                                      <div className="mb-3">
                                        <Select
                                          // defaultValue={
                                          // !!isEdit
                                          //   ? (selectedOptions[question.id]
                                          //       ? selectedOptions[question.id].map(option => ({
                                          //           label: parse(option.label), // Parse the label here
                                          //           value: option.value
                                          //         }))
                                          //       : [])
                                          //   : (selectedOptions[question.id] || [])

                                          // defaultValue= {[]
                                          // defaultValue = {(selectedOptions[question.id] || [])

                                          defaultValue={
                                            selectedOptions[question.id]
                                              ? selectedOptions[
                                                  question.id
                                                ].map((option) => ({
                                                  label: (
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html: option.label,
                                                      }}
                                                    />
                                                  ), // Parse the label here
                                                  // htmlTxt: option.label,
                                                  value: option.value,
                                                }))
                                              : []

                                            // defaultValue={
                                            //   Array.isArray(selectedOptions[question.id])
                                            //       ? selectedOptions[question.id].map(option => ({
                                            //           label: parse(option.label), // Parse the label here
                                            //           value: option.value
                                            //       }))
                                            //       : [] // Fallback to an empty array if it's not an array
                                          }
                                          placeholder="Select Answers..."
                                          isMulti={true}
                                          options={question.answers.map(
                                            (item) => ({
                                              label: (
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.description,
                                                  }}
                                                />
                                              ),
                                              value: item.id,
                                            })
                                          )}
                                          classNamePrefix={question.id}
                                          closeMenuOnSelect={false}
                                          components={animatedComponents}
                                          onChange={(selected) =>
                                            handleSelectChange(
                                              selected,
                                              question.id
                                            )
                                          } // Pass question ID to handler
                                          // onChange={handleSelectChange}
                                          // isDisabled = {!!isEdit ? true: false}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          </CardBody>
                        </Card>
                        <Row>
                          <Col>
                            <div className="text-end">
                              {/* {!!isEdit ?  null:  */}
                              <button
                                type="submit"
                                className="btn btn-primary save-program"
                              >
                                {!!isEdit ? "Save" : "Create"}
                              </button>
                              {/* } */}
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
          {loading && (
            <div className="loading-container">
              <div className="loading">
                <ReactLoading
                  type="spinningBubbles"
                  color="#007bff"
                  height={100}
                  width={100}
                />
              </div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default programsList;
