import React from "react";
import { Link } from "react-router-dom";
import { size, map } from "lodash";
import { Input, Label,   Badge, } from "reactstrap";

const Img = (cell) => {
  return (
    <React.Fragment>
      {!cell.value ? (
        <div className="avatar-sm d-inline-block align-middle me-2">
          <div className="avatar-title bg-light-subtle text-light font-size-24 m-0 rounded-circle">
            <i className="bx bxs-user-circle"></i>
          </div>
        </div>
      ) : (
        <div>
          <img className="rounded-circle avatar-sm" src={cell.value} alt="" />
        </div>
      )}
    </React.Fragment>
  );
};

const Name = (cell) => {
  return (
    <React.Fragment>
      <h5 className="font-size-14 mb-1">
        <Link to="#" className="text-dark">
          {cell.value}
        </Link>
      </h5>
      <p className="text-muted mb-0">{cell.designation}</p>
    </React.Fragment>
  );
};


const IsLocked = (cell) => {
  return (
    <React.Fragment>

      {cell.value ? <Badge className="bg-primary font-size-12">Yes</Badge> : <Badge className="bg-danger font-size-12">NO</Badge>}
          
      {/* <div className="square-switch">
        <Input
          type="checkbox"
          id="square-switch"
          switch="none"
          checked={cell.value}
          disabled
        />
        <Label
          htmlFor="square-switch"
          data-on-label="Yes"
          data-off-label="No"
        ></Label>
      </div> */}

    </React.Fragment>
  );
};

const Id = (cell) => {
  return cell.value ? cell.value : "";
};

const Email = (cell) => {
  return cell.value ? cell.value : "";
};

const UserName = (cell) => {
  return cell.value ? cell.value : "";
};

const Description = (cell) => {
  return cell.value ? cell.value : "";
};

const PhoneNumber = (cell) => {
  return cell.value ? cell.value : "";
};

const SubscriptionValue = (cell) => {
  return cell.value ? cell.value + " months" : "";
};

const CreatedAt = (cell) => {
  // Check if cell value exists
  if (!cell.value) {
    return "";
  }

  // Create a new Date object
  const date = new Date(cell.value);

  // Format the date using Intl.DateTimeFormat
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: "UTC", // Ensure consistent timezone handling
  }).format(date);

  return <span>{formattedDate}</span>;
};

const UpdatedAt = (cell) => {
  // Check if cell value exists
  if (!cell.value) {
    return "";
  }

  // Create a new Date object
  const date = new Date(cell.value);

  // Format the date using Intl.DateTimeFormat
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: "UTC", // Ensure consistent timezone handling
  }).format(date);

  return <span>{formattedDate}</span>;
};

const Tags = (cell) => {
  return (
    <React.Fragment>
      {map(
        cell.value,
        (tag, index) =>
          index < 2 && (
            <Link
              to="#"
              className="badge bg-primary-subtle text-primary font-size-11 m-1"
              key={"_skill_" + cell.id + index}
            >
              {tag}
            </Link>
          )
      )}
      {size(cell.value) > 2 && (
        <Link
          to="#"
          className="badge bg-primary-subtle text-primary font-size-11 m-1"
          key={"_skill_" + cell.id}
        >
          {size(cell.value) - 1} + more
        </Link>
      )}
    </React.Fragment>
  );
};
export {
  Name,
  Email,
  Description,
  Id,
  Tags,
  UserName,
  IsLocked,
  PhoneNumber,
  SubscriptionValue,
  CreatedAt,
  UpdatedAt,
  Img,
};
