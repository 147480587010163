/* POINT12S */
export const GET_POINT12S = "GET_POINT12S"
export const GET_POINT12S_BY_ID = "GET_POINT12S_BY_ID"
export const GET_POINT12S_SUCCESS = "GET_POINT12S_SUCCESS"
export const GET_POINT12S_FAIL = "GET_POINT12S_FAIL"

/* POINT12S PROFILE */
export const GET_POINT12_PROFILE = "GET_POINT12_PROFILE"
export const GET_POINT12_PROFILE_SUCCESS = "GET_POINT12_PROFILE_SUCCESS"
export const GET_POINT12_PROFILE_FAIL = "GET_POINT12_PROFILE_FAIL"

/**
 * add point12
 */
export const ADD_NEW_POINT12 = "ADD_NEW_POINT12"
export const ADD_POINT12_SUCCESS = "ADD_POINT12_SUCCESS"
export const ADD_POINT12_FAIL = "ADD_POINT12_FAIL"

/**
 * Edit point12
 */
export const UPDATE_POINT12 = "UPDATE_POINT12"
export const UPDATE_POINT12_SUCCESS = "UPDATE_POINT12_SUCCESS"
export const UPDATE_POINT12_FAIL = "UPDATE_POINT12_FAIL"

/**
 * Delete point12
 */
export const DELETE_POINT12 = "DELETE_POINT12"
export const DELETE_POINT12_SUCCESS = "DELETE_POINT12_SUCCESS"
export const DELETE_POINT12_FAIL = "DELETE_POINT12_FAIL"
