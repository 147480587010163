import {
  GET_REWARDS_SUCCESS,
  GET_REWARDS_FAIL,
  ADD_REWARD_SUCCESS,
  ADD_REWARD_FAIL,
  UPDATE_REWARD_SUCCESS,
  UPDATE_REWARD_FAIL,
  DELETE_REWARD_SUCCESS,
  DELETE_REWARD_FAIL,
  GET_REWARD_PROFILE_SUCCESS,
  GET_REWARD_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  rewards: [],
  rewardProfile: {},
  error: {},
}

const rewards = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REWARDS_SUCCESS:
      return {
        ...state,
        rewards: action.payload,
      }

    case GET_REWARDS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_REWARD_SUCCESS:

      return {
        ...state,
        rewards: [action.payload, ...state.rewards],
      }

    case ADD_REWARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_REWARD_PROFILE_SUCCESS:
      return {
        ...state,
        rewardProfile: action.payload,
      }

    case UPDATE_REWARD_SUCCESS:
      return {
        ...state,
        rewards: state.rewards.map(reward =>
          reward.id.toString() === action.payload.id.toString()
            ? { reward, ...action.payload }
            : reward
        ),
      }

    case UPDATE_REWARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_REWARD_SUCCESS:
      return {
        ...state,
        rewards: state.rewards.filter(
          reward => reward.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_REWARD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_REWARD_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default rewards
