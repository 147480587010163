import axios from "axios";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import TableContainer from "../../../components/Common/TableContainer";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  FormFeedback,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";
import Dropzone from "react-dropzone";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Name,
  Email,
  Description,
  Day,
  Tags,
  UpdatedAt,
  CreatedAt,
  Projects,
  Img,
} from "./session12listCol";

import ReactLoading from "react-loading";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getSession12s as onGetSession12s,
  getSession12sById as onGetSession12sById,
  addNewSession12 as onAddNewSession12,
  updateSession12 as onUpdateSession12,
  deleteSession12 as onDeleteSession12,
} from "../../../store/actions";

import {
  getPrograms12 as onGetPrograms12,
  getPrograms12ById as onGetPrograms12ById,
} from "../../../store/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const session12sList = () => {
  document.title = "ACUSOLO | Admin & Dashboard";

  const durationnotify = () =>
    toast("Please select the program. ", {
      position: "top-center",
      hideProgressBar: false,
      className: "bg-danger text-white",
    });
  const errornotify = () =>
    toast("Error ! An error occurred.", {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-danger text-white",
      transition: Slide,
    });
  const editorRef = useRef();
  const [editor, setEditor] = useState(false);
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  const days = [  
    { id: 1, name: '1' },  
    { id: 2, name: '2' },
    { id: 3, name: '3' },  
    { id: 4, name: '4' },  
    { id: 5, name: '5' },  
    { id: 6, name: '6' },  
  ];  
  // const [ state, setState ]  = useState("All");
  const [selectedValue, setSelectedValue] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedName, setSelectedName] = useState("All");
  const dispatch = useDispatch();
  const [session12, setSession12] = useState();
  const [program, setprogram] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const onSelectData = (value, name) => {
    setSelectedValue(value);
    setSelectedName(name);
    // setState(value);
    // dispatch(getPrograms(value));
    dispatch(onGetSession12sById(value));
  };

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    setEditor(true);
  }, []);
  const [data, setData] = useState("");
  const [editorData, setEditorData] = useState("");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();

    setEditorData(data);
    setSession12({
      ...session12,
      description: data,
    });
  };

  // const handleEditorChange = (event, editor) => {
  //   const data = editor.getData();
  //   console.log(session12);
  //   setEditorData(data);
  //   setSession12((prevSession12) => ({
  //     ...prevSession12,
  //     description: data,
  //   }));
  // };

  const handleSelectChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    // console.log(selectedOption);

    const value = selectedOption.value;
    const name = selectedOption.text; // This gets the text inside the option
    onSelectData(value, name);
  };

  const handleSelectDayChange = (Dayevent) => {
    const selectedOption = Dayevent.target.options[Dayevent.target.selectedIndex];
    const value = selectedOption.value;
    const name = selectedOption.text;    
    setSelectedDay(value);
  }

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      pid: (session12 && session12.pid) || "",
      pname: (session12 && session12.pname) || "",
      name: (session12 && session12.name) || "",
      day: (session12 && session12.day) || "",
      description: (session12 && session12.description) || "",
      image: (selectedFiles && selectedFiles[0]) || null,

    },
    validationSchema: Yup.object({
      pname: Yup.string().required("Please Enter Program Name"),
      name: Yup.string().required("Please Enter 12 Point Session Name"),
      description: Yup.string().required("Please Enter Session12 Description"),
      image: Yup.mixed().required('Please upload an image'),

    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateSession12 = {
          id: session12.id,
          name: values.name,
          description: values.description,
        };

        const formData = new FormData();

        Object.keys(updateSession12).forEach((key) => {
          formData.append(key, updateSession12[key]);
        });

        if (selectedFiles[0]) {
          formData.append("file", selectedFiles[0]);
        }

        // update session12
        dispatch(onUpdateSession12(formData, updateSession12.id));
        validation.resetForm();
        setIsEdit(false);

      } else {
        const newSession12 = {
          pid: values["pid"],
          name: values["name"],
          description: values["description"],
        };

        const formData = new FormData();

        Object.keys(newSession12).forEach((key) => {
          formData.append(key, newSession12[key]);
        });

        if (selectedFiles[0]) {
          formData.append("file", selectedFiles[0]);
        }    

        // save new session12
        dispatch(onAddNewSession12(formData));
        validation.resetForm();
      }
      toggle();
    },
  });

  const session12listData = createSelector(
    (state) => state.session12s,
    (state) => ({
      session12s: state.session12s,
      loading: state.loading,
    })
  );

  const programlistData = createSelector(
    (state) => state.programs12,
    (state) => ({
      programs12: state.programs12,
    })
  );

  // Inside your component
  const { session12s, loading } = useSelector(session12listData);
  const { programs12 } = useSelector(programlistData);

  const columns = useMemo(
    () => [
      {  
        Header: "No",  
        Cell: ({ row, state }) => {  
          const { pageIndex, pageSize } = state; // Get pageIndex and pageSize from state  
          const rowIndex = row.index + 1; // Calculate the row number  
          return <label className="">{rowIndex}</label>;  
        },  
      },
      {
        Header: "Image",
        accessor: "url",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Img {...cellProps} />;
        },
      },
      {
        Header: "Name",
        filterable: false,
        disableFilters: true,
        accessor: "name",
        Cell: (session12) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {session12.row.original.name}
              </Link>
            </h5>
            <p className="text-muted mb-0">
              {session12.row.original.designation}
            </p>
          </>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        width: "70%",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Description value={cellProps.value} />;
        },
      },
      {
        Header: "CreatedAt",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CreatedAt {...cellProps} />;
        },
      },

      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <UpdatedAt {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const session12Data = cellProps.row.original;
                    handleSession12Click(session12Data);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const session12Data = cellProps.row.original;
                    onClickDelete(session12Data);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (programs12 && !programs12.length && !isEmpty(programs12)) {
      dispatch(onGetPrograms12());
      setIsEdit(false);
    }
  }, [dispatch, programs12]);

  useEffect(() => {
    if (session12s && !session12s.length && !isEmpty(session12s)) {
      dispatch(onGetSession12s());
      setIsEdit(false);
    }
  }, [dispatch, session12s]);

  useEffect(() => {
    dispatch(onGetSession12s());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    dispatch(onGetPrograms12());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    setSession12(session12s);
    setIsEdit(false);
  }, [session12s]);

  useEffect(() => {
    setprogram(programs12);
    // setIsEdit(false);
  }, [programs12]);

  useEffect(() => {
    if (!isEmpty(session12s) && !!isEdit) {
      setSession12(session12s);
      setIsEdit(false);
    }
  }, [session12s]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSession12Click = (arg) => {
    const session12 = arg;
    setSession12({
      id: session12.id,
      pname: session12.pname,
      name: session12.name,
      description: session12.description,
    });

    setIsEdit(true);

    toggle();
  };

  const handleSession12Clicks = (result) => {
    // const session12 = arg;
    setSession12({
      pid: result.selectedValue,
      pname: result.selectedName,
      // description: result.editorData,
    });

    setIsEdit(false);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete session12
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (session12s) => {
    setSession12(session12s);
    setDeleteModal(true);
  };

  const handleDeleteSession12 = () => {
    dispatch(onDeleteSession12(session12.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSession12}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Col lg="12">
                <Row className="align-items-center">
                  <Col>
                    <div className="mb-3">
                      <h5 className="card-title">
                        12 Points Sessions List{" "}
                        <span className="text-muted fw-normal ms-2">
                          ({session12s.length})
                        </span>
                      </h5>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                      <Card>
                        <CardHeader>
                          {/* <h4 className="card-title mb-1">Session12 Editor</h4> */}
                          <p className="text-muted mb-0">
                          Before add new 12 points sessions, <code>check</code> selection Program.
                          </p>
                          <br></br>
                          <div className="row">
                            <div className="col-sm-7 col-md-7">
                              <div className="">
                                {/* <h5 className="card-title me-2">Program : </h5> */}
                                <div className="ms-auto">
                                  <select
                                    className="form-select form-select-md"
                                    onChange={handleSelectChange}
                                  >
                                    <option value="00000000-0000-0000-0000-000000000000">
                                      Select Programs...
                                    </option>
                                    {programs12.map((program) => (
                                      <option
                                        key={program.id}
                                        value={program.id}
                                      >
                                        {program.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* <div style={{display: 'flex'}} className="justify-content-end col-sm-6"> */}
                            <div
                              style={{ display: "flex" }}
                              className="col-sm-5 col-md-5 justify-content-end"
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                // onClick={handleSession12Clicks}
                                onClick={() => {
                                  if (
                                    selectedValue == "All" ||
                                    selectedValue ==
                                      "00000000-0000-0000-0000-000000000000"
                                  ) {
                                    durationnotify();
                                    // errornotify();
                                  } else {
                                    const result = {
                                      editorData,
                                      selectedName,
                                      selectedValue,
                                    };
                                    handleSession12Clicks(result);
                                  }
                                }}
                              >
                                <i className="bx bx-primary me-1"></i> Add new
                              </Link>
                            </div>
                          </div>
                        </CardHeader>
                        {/* <CardBody>
                  
                        </CardBody> */}
                      </Card>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={12}>
                    <TableContainer
                      columns={columns}
                      data={session12s}
                      isGlobalFilter={true}
                      isAddSession12List={true}
                      customPageSize={10}
                      className="table align-middle datatable dt-responsive table-check nowrap"
                    />

                    <Modal isOpen={modal} toggle={toggle}>
                      <ModalHeader toggle={toggle} tag="h4">
                        {!!isEdit ? "Edit Session12" : "Add Session12"}
                      </ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <Row>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">Program</Label>
                                <Input
                                  name="pname"
                                  label="Pname"
                                  type="text"
                                  disabled
                                  placeholder="Insert Program"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.pname || ""}
                                  invalid={
                                    validation.touched.pname &&
                                    validation.errors.pname
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                              <div className="mb-3">
                                <Col className="col-12">
                                  <Label className="form-label">Image</Label>
                                  <Card>
                                    <CardBody>
                                      <Dropzone
                                        onDrop={(acceptedFiles) => {
                                          handleAcceptedFiles(acceptedFiles);
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div
                                              className="dz-message needsclick mt-2"
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="mb-1">
                                                <i className="display-6 text-muted bx bx-cloud-upload" />
                                                <h6>
                                                  Drop files here or click to
                                                  upload.
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div
                                        className="dropzone-previews mt-3"
                                        id="file-previews"
                                      >
                                        {selectedFiles.map((f, i) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={i + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center col-12">
                                                  <Col xs={12}>
                                                    <Col className="col-auto">
                                                      <img
                                                        data-dz-thumbnail=""
                                                        height="300"
                                                        className="avatar-lg rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                      />
                                                      <span
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <Link
                                                          to="#"
                                                          className="text-muted font-weight-bold"
                                                        >
                                                          &nbsp;{f.name}
                                                        </Link>

                                                        {/* <p className=""> */}
                                                        <strong>
                                                          &nbsp;
                                                          {"(" +
                                                            f.formattedSize +
                                                            ")"}
                                                        </strong>
                                                        {/* </p> */}
                                                      </span>
                                                    </Col>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          );
                                        })}
                                      </div>
                                    </CardBody>
                                    { validation.touched.image && validation.errors.image && !selectedFiles[0] ? (
                                    <FormFeedback type="invalid" className="d-block">  
                                    {validation.errors.image}  
                                  </FormFeedback>
                                    ) : null}
                                  </Card>
                                </Col>
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">
                                  12 Point Session Name
                                </Label>
                                <Input
                                  name="name"
                                  type="text"
                                  placeholder="Insert Session12 Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    validation.touched.name &&
                                    validation.errors.name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.name &&
                                validation.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.name}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">
                                  Description
                                </Label>
                                <Input
                                  name="description"
                                  label="Description"
                                  type="text"
                                  placeholder="Insert Description"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.description || ""}
                                  invalid={
                                    validation.touched.description &&
                                    validation.errors.description
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.description &&
                                validation.errors.description ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.description}
                                  </FormFeedback>
                                ) : null}

                                <Input
                                  name="pid"
                                  label="Pid"
                                  type="text"
                                  placeholder="Insert Program"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.pid || ""}
                                  invalid={
                                    validation.touched.pid &&
                                    validation.errors.pid
                                      ? true
                                      : false
                                  }
                                  style={{ visibility: "hidden" }}
                                />
                                {validation.touched.pid &&
                                validation.errors.pid ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.pid}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary save-session12"
                                >
                                  {!!isEdit ? "Save" : "Create"}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </Col>
                </Row>
              </Col>
            </Col>
            {/* <Col xl={4}>
            </Col> */}
          </Row>
          <ToastContainer />
          {loading && (
            <div className="loading-container">
              <div className="loading">
                <ReactLoading
                  type="spinningBubbles"
                  color="#007bff"
                  height={100}
                  width={100}
                />
              </div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default session12sList;
