import { patch, call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ANSWERS, GET_ANSWERS_BY_ID, GET_ANSWER_PROFILE, ADD_NEW_ANSWER, DELETE_ANSWER, UPDATE_ANSWER } from "./actionTypes"

import {
  getAnswersSuccess,
  getAnswersFail,
  getAnswerProfileSuccess,
  getAnswerProfileFail,
  addAnswerFail,
  addAnswerSuccess,
  updateAnswerSuccess,
  updateAnswerFail,
  deleteAnswerSuccess,
  deleteAnswerFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getAnswers, getAnswersById, getAnswerProfile, addNewAnswer, updateAnswer, deleteAnswer } from "../../helpers/helper"
import answers from "./reducer"

function* fetchAnswers() {
  try {
    const response = yield call(getAnswers)
    yield put(getAnswersSuccess(response))
  } catch (error) {
    yield put(getAnswersFail(error))
  }
}

function* fetchAnswersById({ payload: id}) {
  try {
    const response = yield call(getAnswersById, id)
    yield put(getAnswersSuccess(response))
  } catch (error) {
    yield put(getAnswersFail(error))
  }
}

function* fetchAnswerProfile() {
  try {
    const response = yield call(getAnswerProfile)
    yield put(getAnswerProfileSuccess(response))
  } catch (error) {
    yield put(getAnswerProfileFail(error))
  }
}

function* onUpdateAnswer({ payload: answer }) {
  try {
    // delete answer.id
    const id = answer.id
    const response = yield call(updateAnswer, answer, id)

    yield put(updateAnswerSuccess(response))
  } catch (error) {
    yield put(updateAnswerFail(error))
  }
}

function* onDeleteAnswer({ payload: answer }) {
  try {
    const response = yield call(deleteAnswer, answer)
    yield put(deleteAnswerSuccess(response))
  } catch (error) {
    yield put(deleteAnswerFail(error))
  }
}

function* onAddNewAnswer({ payload: answer }) {

  try {
    const response = yield call(addNewAnswer, answer)

    yield put(addAnswerSuccess(response))
  } catch (error) {

    yield put(addAnswerFail(error))
  }
}

function* answersSaga() {
  yield takeEvery(GET_ANSWERS, fetchAnswers)
  yield takeEvery(GET_ANSWERS_BY_ID, fetchAnswersById)
  yield takeEvery(GET_ANSWER_PROFILE, fetchAnswerProfile)
  yield takeEvery(ADD_NEW_ANSWER, onAddNewAnswer)
  yield takeEvery(UPDATE_ANSWER, onUpdateAnswer)
  yield takeEvery(DELETE_ANSWER, onDeleteAnswer)
}

export default answersSaga;
