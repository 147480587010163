import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//contacts
// import contacts from "./contacts/reducer"

//programs
import programs from "./programs/reducer"

//questions
import questions from "./questions/reducer"

//answers
import answers from "./answers/reducer"

//categories
import categories from "./categories/reducer"

//users
import users from "./users/reducer"

//rewards
import rewards from "./rewards/reducer"

//12 Points
import points12 from "./points12/reducer"

//Sessions
import sessions from "./sessions/reducer"

//Devices
import devices from "./devices/reducer"

//12 Point Program
import programs12 from "./programs12/reducer"

//12 Point Session
import session12s from "./session12s/reducer"

//programs


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  programs,
  questions,
  answers,
  categories,
  users,
  rewards,
  points12,
  sessions,
  devices,
  programs12,
  session12s,
})

export default rootReducer
