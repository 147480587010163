import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap";
import { map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Card
import Carddevice from "./card-device";

//redux
import { useSelector, useDispatch } from "react-redux";

import { getDevices as onGetDevices } from "../../store/devices/actions";
import { createSelector } from "reselect";
import Pagination from "../../components/Common/Pagination";

const devicesGrid = () => {

  //meta title
  document.title = "ACUSOLO | Admin & Dashboard";

  const dispatch = useDispatch();

  const devicegridData = createSelector(

    (state) => state.devices,
    (state) => ({
      devices: state.devices,
    })
  );
  // Inside your component
  const { devices } = useSelector(devicegridData);

  const [device, setdevice] = useState([])

    // pagination
    const [currentPage, setCurrentPage] = useState(1);

    const perPageData = devices.length < 8 ? devices.length : 8;
    const indexOfLast = currentPage * perPageData;
    const indexOfFirst = indexOfLast - perPageData;
  
    const currentdata = useMemo(() => devices?.slice(indexOfFirst, indexOfLast), [devices, indexOfFirst, indexOfLast])
  
    // get data
    useEffect(() => {
      dispatch(onGetDevices())
    }, [dispatch]);
  
    useEffect(() => {
      setdevice(currentdata)
    }, [currentdata])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="devices" breadcrumbItem="Device Grid" /> */}
          <Row className="align-items-center">
            <Col md={6}>
              <div className="mb-3">
                <h5 className="card-title">
                  Devices Grid{" "}
                  <span className="text-muted fw-normal ms-2">({devices.length})</span>
                </h5>
              </div>
            </Col>

            <Col md={6}>
              <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                <div>
                  <Nav pills>
                    <NavItem>
                      <NavLink
                        href="/devices-list"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        id="list"
                      >
                        <i className="bx bx-list-ul"></i>
                        <UncontrolledTooltip placement="top" target="list">
                          List
                        </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="/devices-grid"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        className="active"
                        id="Grid"
                      >
                        <i className="bx bx-grid-alt"></i>
                        <UncontrolledTooltip placement="top" target="Grid">
                          Grid
                        </UncontrolledTooltip>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <div>
                  <Link to="#" className="btn btn-light">
                    <i className="bx bx-plus me-1"></i> Add New
                  </Link>
                </div>

                {/* <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn btn-link text-muted py-1 font-size-16 shadow-none"
                    tag="a"
                  >
                    <i className="bx bx-dots-horizontal-rounded"></i>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <li>
                      <DropdownItem to="#">Action</DropdownItem>
                    </li>
                    <li>
                      <DropdownItem to="#">Another action</DropdownItem>
                    </li>
                    <li>
                      <DropdownItem to="#">Something else here</DropdownItem>
                    </li>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
              </div>
            </Col>
          </Row>
          <Row>
            {map(device, (device, key) => (
              <Carddevice device={device} key={"_device_" + key} />
            ))}
          </Row>
          <Pagination
            perPageData={perPageData}
            data={devices}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            currentData={device}
            className="align-items-center justify-content-between text-center text-sm-start mb-3" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default devicesGrid;