/* PROGRAMS12 */
export const GET_PROGRAMS12 = "GET_PROGRAMS12"
export const GET_PROGRAMS12_BY_ID = "GET_PROGRAMS12_BY_ID"
export const GET_PROGRAMS12_SUCCESS = "GET_PROGRAMS12_SUCCESS"
export const GET_PROGRAMS12_FAIL = "GET_PROGRAMS12_FAIL"

/* PROGRAMS12 PROFILE */
export const GET_PROGRAM12_PROFILE = "GET_PROGRAM12_PROFILE"
export const GET_PROGRAM12_PROFILE_SUCCESS = "GET_PROGRAM12_PROFILE_SUCCESS"
export const GET_PROGRAM12_PROFILE_FAIL = "GET_PROGRAM12_PROFILE_FAIL"

/**
 * add program12
 */
export const ADD_NEW_PROGRAM12 = "ADD_NEW_PROGRAM12"
export const ADD_PROGRAM12_SUCCESS = "ADD_PROGRAM12_SUCCESS"
export const ADD_PROGRAM12_FAIL = "ADD_PROGRAM12_FAIL"

/**
 * Edit program12
 */
export const UPDATE_PROGRAM12 = "UPDATE_PROGRAM12"
export const UPDATE_PROGRAM12_SUCCESS = "UPDATE_PROGRAM12_SUCCESS"
export const UPDATE_PROGRAM12_FAIL = "UPDATE_PROGRAM12_FAIL"

/**
 * Delete program12
 */
export const DELETE_PROGRAM12 = "DELETE_PROGRAM12"
export const DELETE_PROGRAM12_SUCCESS = "DELETE_PROGRAM12_SUCCESS"
export const DELETE_PROGRAM12_FAIL = "DELETE_PROGRAM12_FAIL"
