import { patch, call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_REWARDS, GET_REWARDS_BY_ID, GET_REWARD_PROFILE, ADD_NEW_REWARD, DELETE_REWARD, UPDATE_REWARD } from "./actionTypes"

import {
  getRewardsSuccess,
  getRewardsFail,
  getRewardProfileSuccess,
  getRewardProfileFail,
  addRewardFail,
  addRewardSuccess,
  updateRewardSuccess,
  updateRewardFail,
  deleteRewardSuccess,
  deleteRewardFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getRewards, getRewardsById, getRewardProfile, addNewReward, updateReward, deleteReward } from "../../helpers/helper"

function* fetchRewards() {
  try {
    const response = yield call(getRewards)
    yield put(getRewardsSuccess(response))
  } catch (error) {
    yield put(getRewardsFail(error))
  }
}

function* fetchRewardsById({payload: reward}) {
  try {
    const response = yield call(getRewards)
    yield put(getRewardsSuccess(response))
  } catch (error) {
    yield put(getRewardsFail(error))
  }
}

function* fetchRewardProfile() {
  try {
    const response = yield call(getRewardProfile)
    yield put(getRewardProfileSuccess(response))
  } catch (error) {
    yield put(getRewardProfileFail(error))
  }
}

function* onUpdateReward({ payload: reward }) {
  try {
    // delete reward.id
    const id = reward.id
    const response = yield call(updateReward, reward, id)
    yield put(updateRewardSuccess(response))
  } catch (error) {
    yield put(updateRewardFail(error))
  }
}

function* onDeleteReward({ payload: reward }) {
  try {
    const response = yield call(deleteReward, reward)
    yield put(deleteRewardSuccess(response))
  } catch (error) {
    yield put(deleteRewardFail(error))
  }
}

function* onAddNewReward({ payload: reward }) {

  try {
    const response = yield call(addNewReward, reward)

    yield put(addRewardSuccess(response))
  } catch (error) {

    yield put(addRewardFail(error))
  }
}

function* rewardsSaga() {
  yield takeEvery(GET_REWARDS, fetchRewards)
  yield takeEvery(GET_REWARDS_BY_ID, fetchRewardsById)
  yield takeEvery(GET_REWARD_PROFILE, fetchRewardProfile)
  yield takeEvery(ADD_NEW_REWARD, onAddNewReward)
  yield takeEvery(UPDATE_REWARD, onUpdateReward)
  yield takeEvery(DELETE_REWARD, onDeleteReward)
}

export default rewardsSaga;
