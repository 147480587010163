import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
// import contactsSaga from "./contacts/saga";
import programsSaga from "./programs/saga";
import questionsSaga from "./questions/saga";
import answersSaga from "./answers/saga";
import categoriesSaga from "./categories/saga";
import usersSaga from "./users/saga";
import rewardsSaga from "./rewards/saga";
import points12Saga from "./points12/saga";
import sessionsSaga from "./sessions/saga";
import devicesSaga from "./devices/saga";
import programs12Saga from "./programs12/saga";
import session12sSaga from "./session12s/saga";


export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
 
    fork(programsSaga),
    fork(questionsSaga),
    fork(answersSaga),
    fork(categoriesSaga),
    fork(usersSaga),
    fork(rewardsSaga),
    fork(points12Saga),
    fork(sessionsSaga),
    fork(devicesSaga),
    fork(programs12Saga),
    fork(session12sSaga)

  ])
}
