import { patch, call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_QUESTIONS, GET_QUESTIONS_BY_ID, GET_QUESTION_PROFILE, ADD_NEW_QUESTION, DELETE_QUESTION, UPDATE_QUESTION } from "./actionTypes"

import {
  getQuestionsSuccess,
  getQuestionsFail,
  getQuestionProfileSuccess,
  getQuestionProfileFail,
  addQuestionFail,
  addQuestionSuccess,
  updateQuestionSuccess,
  updateQuestionFail,
  deleteQuestionSuccess,
  deleteQuestionFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getQuestions, getQuestionsById, getQuestionProfile, addNewQuestion, updateQuestion, deleteQuestion } from "../../helpers/helper"

function* fetchQuestions() {
  try {
    const response = yield call(getQuestions)
    yield put(getQuestionsSuccess(response))
  } catch (error) {
    yield put(getQuestionsFail(error))
  }
}

function* fetchQuestionsById({payload: question}) {
  try {
    const response = yield call(getQuestions)
    yield put(getQuestionsSuccess(response))
  } catch (error) {
    yield put(getQuestionsFail(error))
  }
}

function* fetchQuestionProfile() {
  try {
    const response = yield call(getQuestionProfile)
    yield put(getQuestionProfileSuccess(response))
  } catch (error) {
    yield put(getQuestionProfileFail(error))
  }
}

function* onUpdateQuestion({ payload: question }) {
  try {
    // delete question.id
    const id = question.id
    const response = yield call(updateQuestion, question, id)
    yield put(updateQuestionSuccess(response))
  } catch (error) {
    yield put(updateQuestionFail(error))
  }
}

function* onDeleteQuestion({ payload: question }) {
  try {
    const response = yield call(deleteQuestion, question)
    yield put(deleteQuestionSuccess(response))
  } catch (error) {
    yield put(deleteQuestionFail(error))
  }
}

function* onAddNewQuestion({ payload: question }) {

  try {
    const response = yield call(addNewQuestion, question)

    yield put(addQuestionSuccess(response))
  } catch (error) {

    yield put(addQuestionFail(error))
  }
}

function* questionsSaga() {
  yield takeEvery(GET_QUESTIONS, fetchQuestions)
  yield takeEvery(GET_QUESTIONS_BY_ID, fetchQuestionsById)
  yield takeEvery(GET_QUESTION_PROFILE, fetchQuestionProfile)
  yield takeEvery(ADD_NEW_QUESTION, onAddNewQuestion)
  yield takeEvery(UPDATE_QUESTION, onUpdateQuestion)
  yield takeEvery(DELETE_QUESTION, onDeleteQuestion)
}

export default questionsSaga;
