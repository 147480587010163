import {
  GET_SESSION12S_SUCCESS,
  GET_SESSION12S_FAIL,
  ADD_NEW_SESSION12,
  ADD_SESSION12_SUCCESS,
  ADD_SESSION12_FAIL,
  UPDATE_SESSION12_SUCCESS,
  UPDATE_SESSION12_FAIL,
  DELETE_SESSION12_SUCCESS,
  DELETE_SESSION12_FAIL,
  GET_SESSION12_PROFILE_SUCCESS,
  GET_SESSION12_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  session12s: [],
  session12Profile: {},
  error: {},
  loading: false,
}

const session12s = (state = INIT_STATE, action) => {
  
  switch (action.type) {
    case GET_SESSION12S_SUCCESS:
      return {
        ...state,
        session12s: action.payload,
      }

    case GET_SESSION12S_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    
    case ADD_NEW_SESSION12:
      return {
        ...state,
        loading: true,
      }

    case ADD_SESSION12_SUCCESS:

      return {
        ...state,
        session12s: [action.payload, ...state.session12s],
        loading: false,
        error: null,
      }

    case ADD_SESSION12_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SESSION12_PROFILE_SUCCESS:
      return {
        ...state,
        session12Profile: action.payload,
      }

    case UPDATE_SESSION12_SUCCESS:
      return {
        ...state,
        session12s: state.session12s.map(session12 =>
          session12.id.toString() === action.payload.id.toString()
            ? { session12, ...action.payload }
            : session12
        ),
      }

    case UPDATE_SESSION12_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SESSION12_SUCCESS:
      return {
        ...state,
        session12s: state.session12s.filter(
          session12 => session12.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_SESSION12_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SESSION12_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default session12s
