import {
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAIL,
  ADD_NEW_PROGRAM,
  ADD_PROGRAM_SUCCESS,
  ADD_PROGRAM_FAIL,
  UPDATE_PROGRAM,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_FAIL,
  DELETE_PROGRAM_SUCCESS,
  DELETE_PROGRAM_FAIL,
  GET_PROGRAM_PROFILE_SUCCESS,
  GET_PROGRAM_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  programs: [],
  programProfile: {},
  error: {},
  loading: false,
}

const programs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        programs: action.payload,
      }

    case GET_PROGRAMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    
    case ADD_NEW_PROGRAM:
      return {
        ...state,
        loading: true,
      }

    case ADD_PROGRAM_SUCCESS:

      return {
        ...state,
        programs: [action.payload,  ...state.programs ],
        loading: false,
        error: null,
      }

    case ADD_PROGRAM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PROGRAM_PROFILE_SUCCESS:
      return {
        ...state,
        programProfile: action.payload,
      }

    case UPDATE_PROGRAM:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_PROGRAM_SUCCESS:
      return {
        ...state,
        programs: action.payload,
        // programs: state.programs.map(program =>
        //   program.id.toString() === action.payload.id.toString()
        //     ? { program, ...action.payload }
        //     : program
        // ),
        loading: false,
        error: null,
      }

    case UPDATE_PROGRAM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_PROGRAM_SUCCESS:
      return {
        ...state,
        programs: state.programs.filter(
          program => program.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_PROGRAM_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROGRAM_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default programs
