import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

import UserProfile from "../pages/Authentication/user-profile";

//Contact
// import ContactsGrid from "../pages/Contacts/contacts-grid";
// import ContactsList from "../pages/Contacts/ContactList/contacts-list";
// import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile";

//Program
import ProgramsGrid from "../pages/Programs/programs-grid";
import ProgramsList from "../pages/Programs/ProgramList/programs-list";
import ProgramsProfile from "../pages/Programs/ProgramsProfile/programs-profile";

//12Program
import Programs12Grid from "../pages/Programs12/programs12-grid";
import Programs12List from "../pages/Programs12/Program12List/programs12-list";
import Programs12Profile from "../pages/Programs12/Programs12Profile/programs12-profile";

//Question
import QuestionsGrid from "../pages/Questions/questions-grid";
import QuestionsList from "../pages/Questions/QuestionList/questions-list";
import QuestionsProfile from "../pages/Questions/QuestionsProfile/questions-profile";

//User
import UsersGrid from "../pages/Users/users-grid";
import UsersList from "../pages/Users/UserList/users-list";
import UsersProfile from "../pages/Users/UsersProfile/users-profile";

//Category , Categories
import CategoriesGrid from "../pages/Categories/categories-grid";
import CategoriesList from "../pages/Categories/CategoryList/categories-list";
import CategoriesProfile from "../pages/Categories/CategoriesProfile/categories-profile";

//Answer
import AnswersGrid from "../pages/Answers/answers-grid";
import AnswersList from "../pages/Answers/AnswerList/answers-list";
import AnswersProfile from "../pages/Answers/AnswersProfile/answers-profile";

//Reward
import RewardsGrid from "../pages/Rewards/rewards-grid";
import RewardsList from "../pages/Rewards/RewardList/rewards-list";
import RewardsProfile from "../pages/Rewards/RewardsProfile/rewards-profile";

//12 Points
import Points12Grid from "../pages/Points12/points12-grid";
import Points12List from "../pages/Points12/Point12List/points12-list";
import Points12Profile from "../pages/Points12/Points12Profile/points12-profile";

//Sessions
import SessionsGrid from "../pages/Sessions/sessions-grid";
import SessionsList from "../pages/Sessions/SessionList/sessions-list";
import SessionsProfile from "../pages/Sessions/SessionsProfile/sessions-profile";

//12 Pont Sessions
import Session12sGrid from "../pages/Session12s/session12s-grid";
import Session12sList from "../pages/Session12s/Session12List/session12s-list";
import Session12sProfile from "../pages/Session12s/Session12sProfile/session12s-profile";

//Devices
import DevicesGrid from "../pages/Devices/devices-grid";
import DevicesList from "../pages/Devices/DeviceList/devices-list";
import DevicesProfile from "../pages/Devices/DevicesProfile/devices-profile";

//Blockchain
import Blockchain from "../pages/blockchain";
import { components } from "react-select";

const userRoutes = [
  //Blockchain
  { path: "/blockchain", component: <Blockchain/> },

  //dashboard
  { path: "/dashboard", component: <Dashboard/> },

  //profile
  { path: "/profile", component: <UserProfile/> },

  //Contact
  // { path: "/contacts-grid", component: <ContactsGrid/> },
  // { path: "/contacts-list", component: <ContactsList/> },
  // { path: "/contacts-profile", component: <ContactsProfile/> },

  //Program
  { path: "/programs-grid", component: <ProgramsGrid/> },
  { path: "/programs-list", component: <ProgramsList/> },
  { path: "/programs-profile", component: <ProgramsProfile/> },

  //12 Point Program
  { path: "/programs12-grid", component: <Programs12Grid/> },
  { path: "/programs12-list", component: <Programs12List/> },
  { path: "/programs12-profile", component: <Programs12Profile/> },

  //Question
  { path: "/questions-grid", component: <QuestionsGrid/> },
  { path: "/questions-list", component: <QuestionsList/> },
  { path: "/questions-profile", component: <QuestionsProfile/> },

  //User
  { path: "/users-grid", component: <UsersGrid/> },
  { path: "/users-list", component: <UsersList/> },
  { path: "/users-profile", component: <UsersProfile/> },

  //Answer
  { path: "/answers-grid", component: <AnswersGrid/> },
  { path: "/answers-list", component: <AnswersList/> },
  { path: "/answers-profile", component: <AnswersProfile/> },

  //Categories, Category
  { path: "/categories-grid", component: <CategoriesGrid/> },
  { path: "/categories-list", component: <CategoriesList/> },
  { path: "/categories-profile", component: <CategoriesProfile/> },

  //Reward
  { path: "/rewards-grid", component: <RewardsGrid/> },
  { path: "/rewards-list", component: <RewardsList/> },
  { path: "/rewards-profile", component: <RewardsProfile/> },

  //12 Points
  { path: "/12points-grid", component: <Points12Grid/> },
  { path: "/12points-list", component: <Points12List/> },
  { path: "/12points-profile", component: <Points12Profile/> },

  //Sessions
  { path: "/sessions-grid", component: <SessionsGrid/> },
  { path: "/sessions-list", component: <SessionsList/> },
  { path: "/sessions-profile", component: <SessionsProfile/> },

  //12 Point Sessions
  { path: "/session12s-grid", component: <Session12sGrid/> },
  { path: "/session12s-list", component: <Session12sList/> },
  { path: "/session12s-profile", component: <Session12sProfile/> },

   //Devices
   { path: "/devices-grid", component: <DevicesGrid/> },
   { path: "/devices-list", component: <DevicesList/> },
   { path: "/devices-profile", component: <DevicesProfile/> },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const authRoutes = [
  //authencation page
  { path: "/logout", component: <Logout/> },
  { path: "/login", component: <Login/> },
  { path: "/forgot-password", component: <ForgetPwd/> },
  { path: "/register", component: <Register/> },

];

export { userRoutes, authRoutes };
