import { patch, call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SESSIONS, GET_SESSIONS_BY_ID, GET_SESSION_PROFILE, ADD_NEW_SESSION, DELETE_SESSION, UPDATE_SESSION } from "./actionTypes"

import {
  getSessionsSuccess,
  getSessionsFail,
  getSessionProfileSuccess,
  getSessionProfileFail,
  addSessionFail,
  addSessionSuccess,
  updateSessionSuccess,
  updateSessionFail,
  deleteSessionSuccess,
  deleteSessionFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getSessions, getSessionsById, getSessionProfile, addNewSession, updateSession, deleteSession } from "../../helpers/helper"
import sessions from "./reducer"

function* fetchSessions() {
  try {
    const response = yield call(getSessions)
    yield put(getSessionsSuccess(response))
  } catch (error) {
    yield put(getSessionsFail(error))
  }
}

function* fetchSessionsById({ payload: id}) {
  try {
    const response = yield call(getSessionsById, id)
    yield put(getSessionsSuccess(response))
  } catch (error) {
    yield put(getSessionsFail(error))
  }
}

function* fetchSessionProfile() {
  try {
    const response = yield call(getSessionProfile)
    yield put(getSessionProfileSuccess(response))
  } catch (error) {
    yield put(getSessionProfileFail(error))
  }
}

function* onUpdateSession({ payload: session }) {  
  try {  
    // Extract formData and sid from session  
    const sid = session.get('id'); // Replace 'url' with the actual key used in your FormData  

    // Call the updateSession function with formData and sid  
    const response = yield call(updateSession, session, sid);  

    yield put(updateSessionSuccess(response));  
  } catch (error) {  
    yield put(updateSessionFail(error));  
  }  
}  

function* onDeleteSession({ payload: session }) {
  try {
    const response = yield call(deleteSession, session)
    yield put(deleteSessionSuccess(response))
  } catch (error) {
    yield put(deleteSessionFail(error))
  }
}

function* onAddNewSession({ payload: session }) {

  try {
    const response = yield call(addNewSession, session)

    yield put(addSessionSuccess(response))
  } catch (error) {

    yield put(addSessionFail(error))
  }
}

function* sessionsSaga() {
  yield takeEvery(GET_SESSIONS, fetchSessions)
  yield takeEvery(GET_SESSIONS_BY_ID, fetchSessionsById)
  yield takeEvery(GET_SESSION_PROFILE, fetchSessionProfile)
  yield takeEvery(ADD_NEW_SESSION, onAddNewSession)
  yield takeEvery(UPDATE_SESSION, onUpdateSession)
  yield takeEvery(DELETE_SESSION, onDeleteSession)
}

export default sessionsSaga;
