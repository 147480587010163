/* DEVICES */
export const GET_DEVICES = "GET_DEVICES"
export const GET_DEVICES_BY_ID = "GET_DEVICES_BY_ID"
export const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS"
export const GET_DEVICES_FAIL = "GET_DEVICES_FAIL"

/* DEVICES PROFILE */
export const GET_DEVICE_PROFILE = "GET_DEVICE_PROFILE"
export const GET_DEVICE_PROFILE_SUCCESS = "GET_DEVICE_PROFILE_SUCCESS"
export const GET_DEVICE_PROFILE_FAIL = "GET_DEVICE_PROFILE_FAIL"

/**
 * add device
 */
export const ADD_NEW_DEVICE = "ADD_NEW_DEVICE"
export const ADD_DEVICE_SUCCESS = "ADD_DEVICE_SUCCESS"
export const ADD_DEVICE_FAIL = "ADD_DEVICE_FAIL"

/**
 * Edit device
 */
export const UPDATE_DEVICE = "UPDATE_DEVICE"
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS"
export const UPDATE_DEVICE_FAIL = "UPDATE_DEVICE_FAIL"

/**
 * Delete device
 */
export const DELETE_DEVICE = "DELETE_DEVICE"
export const DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS"
export const DELETE_DEVICE_FAIL = "DELETE_DEVICE_FAIL"
