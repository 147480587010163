import {
  GET_SESSION12_PROFILE,
  GET_SESSION12_PROFILE_FAIL,
  GET_SESSION12_PROFILE_SUCCESS,
  GET_SESSION12S,
  GET_SESSION12S_BY_ID,
  GET_SESSION12S_FAIL,
  GET_SESSION12S_SUCCESS,
  ADD_NEW_SESSION12,
  ADD_SESSION12_SUCCESS,
  ADD_SESSION12_FAIL,
  UPDATE_SESSION12,
  UPDATE_SESSION12_SUCCESS,
  UPDATE_SESSION12_FAIL,
  DELETE_SESSION12,
  DELETE_SESSION12_SUCCESS,
  DELETE_SESSION12_FAIL,
} from "./actionTypes"

export const getSession12s = () => ({
  type: GET_SESSION12S,
})

export const getSession12sById = session12 => ({
  type: GET_SESSION12S_BY_ID,
  payload: session12,
})

export const getSession12sSuccess = session12s => ({
  type: GET_SESSION12S_SUCCESS,
  payload: session12s,
})

export const addNewSession12 = session12 => ({
  type: ADD_NEW_SESSION12,
  payload: session12,
})

export const addSession12Success = session12 => ({
  type: ADD_SESSION12_SUCCESS,
  payload: session12,
})

export const addSession12Fail = error => ({
  type: ADD_SESSION12_FAIL,
  payload: error,
})

export const getSession12sFail = error => ({
  type: GET_SESSION12S_FAIL,
  payload: error,
})

export const getSession12Profile = () => ({
  type: GET_SESSION12_PROFILE,
})

export const getSession12ProfileSuccess = session12Profile => ({
  type: GET_SESSION12_PROFILE_SUCCESS,
  payload: session12Profile,
})

export const getSession12ProfileFail = error => ({
  type: GET_SESSION12_PROFILE_FAIL,
  payload: error,
})

export const updateSession12 = ( session12, sid )=> ({
  type: UPDATE_SESSION12,
  payload: session12
})

export const updateSession12Success = session12 => ({
  type: UPDATE_SESSION12_SUCCESS,
  payload: session12,
})

export const updateSession12Fail = error => ({
  type: UPDATE_SESSION12_FAIL,
  payload: error,
})

export const deleteSession12 = session12 => ({
  type: DELETE_SESSION12,
  payload: session12,
})

export const deleteSession12Success = session12 => ({
  type: DELETE_SESSION12_SUCCESS,
  payload: session12,
})

export const deleteSession12Fail = error => ({
  type: DELETE_SESSION12_FAIL,
  payload: error,
})
