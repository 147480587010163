import { patch, call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_POINT12S, GET_POINT12S_BY_ID, GET_POINT12_PROFILE, ADD_NEW_POINT12, DELETE_POINT12, UPDATE_POINT12 } from "./actionTypes"

import {
  getPoints12Success,
  getPoints12Fail,
  getPoint12ProfileSuccess,
  getPoint12ProfileFail,
  addPoint12Fail,
  addPoint12Success,
  updatePoint12Success,
  updatePoint12Fail,
  deletePoint12Success,
  deletePoint12Fail,
} from "./actions"

//Include Both Helper File with needed methods
import { getPoints12, getPoints12ById, getPoint12Profile, addNewPoint12, updatePoint12, deletePoint12 } from "../../helpers/helper"

function* fetchPoints12() {
  try {
    const response = yield call(getPoints12)
    yield put(getPoints12Success(response))
  } catch (error) {
    yield put(getPoints12Fail(error))
  }
}

function* fetchPoints12ById({payload: point12}) {
  try {
    const response = yield call(getPoints12)
    yield put(getPoints12Success(response))
  } catch (error) {
    yield put(getPoints12Fail(error))
  }
}

function* fetchPoint12Profile() {
  try {
    const response = yield call(getPoint12Profile)
    yield put(getPoint12ProfileSuccess(response))
  } catch (error) {
    yield put(getPoint12ProfileFail(error))
  }
}

function* onUpdatePoint12({ payload: point12 }) {
  try {
    // delete point12.id
    const id = point12.get('id');
    
    const response = yield call(updatePoint12, point12, id)
    yield put(updatePoint12Success(response))
  } catch (error) {
    yield put(updatePoint12Fail(error))
  }
}

function* onDeletePoint12({ payload: point12 }) {
  try {
    const response = yield call(deletePoint12, point12)
    yield put(deletePoint12Success(response))
  } catch (error) {
    yield put(deletePoint12Fail(error))
  }
}

function* onAddNewPoint12({ payload: point12 }) {

  try {
    const response = yield call(addNewPoint12, point12)

    yield put(addPoint12Success(response))
  } catch (error) {

    yield put(addPoint12Fail(error))
  }
}

function* points12Saga() {
  yield takeEvery(GET_POINT12S, fetchPoints12)
  yield takeEvery(GET_POINT12S_BY_ID, fetchPoints12ById)
  yield takeEvery(GET_POINT12_PROFILE, fetchPoint12Profile)
  yield takeEvery(ADD_NEW_POINT12, onAddNewPoint12)
  yield takeEvery(UPDATE_POINT12, onUpdatePoint12)
  yield takeEvery(DELETE_POINT12, onDeletePoint12)
}

export default points12Saga;
