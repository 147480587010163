import {
  GET_REWARD_PROFILE,
  GET_REWARD_PROFILE_FAIL,
  GET_REWARD_PROFILE_SUCCESS,
  GET_REWARDS,
  GET_REWARDS_FAIL,
  GET_REWARDS_SUCCESS,
  GET_REWARDS_BY_ID,
  ADD_NEW_REWARD,
  ADD_REWARD_SUCCESS,
  ADD_REWARD_FAIL,
  UPDATE_REWARD,
  UPDATE_REWARD_SUCCESS,
  UPDATE_REWARD_FAIL,
  DELETE_REWARD,
  DELETE_REWARD_SUCCESS,
  DELETE_REWARD_FAIL,
} from "./actionTypes"

export const getRewards = () => ({
  type: GET_REWARDS,
})

export const getRewardsById = rewards => ({
  type: GET_REWARDS_BY_ID,
  payload: rewards,
})

export const getRewardsSuccess = rewards => ({
  type: GET_REWARDS_SUCCESS,
  payload: rewards,
})

export const addNewReward = reward => ({
  type: ADD_NEW_REWARD,
  payload: reward,
})

export const addRewardSuccess = reward => ({
  type: ADD_REWARD_SUCCESS,
  payload: reward,
})

export const addRewardFail = error => ({
  type: ADD_REWARD_FAIL,
  payload: error,
})

export const getRewardsFail = error => ({
  type: GET_REWARDS_FAIL,
  payload: error,
})

export const getRewardProfile = () => ({
  type: GET_REWARD_PROFILE,
})

export const getRewardProfileSuccess = rewardProfile => ({
  type: GET_REWARD_PROFILE_SUCCESS,
  payload: rewardProfile,
})

export const getRewardProfileFail = error => ({
  type: GET_REWARD_PROFILE_FAIL,
  payload: error,
})

export const updateReward = reward => ({
  type: UPDATE_REWARD,
  payload: reward,
})

export const updateRewardSuccess = reward => ({
  type: UPDATE_REWARD_SUCCESS,
  payload: reward,
})

export const updateRewardFail = error => ({
  type: UPDATE_REWARD_FAIL,
  payload: error,
})

export const deleteReward = reward => ({
  type: DELETE_REWARD,
  payload: reward,
})

export const deleteRewardSuccess = reward => ({
  type: DELETE_REWARD_SUCCESS,
  payload: reward,
})

export const deleteRewardFail = error => ({
  type: DELETE_REWARD_FAIL,
  payload: error,
})
