import {
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAIL,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAIL,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAIL,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAIL,
  GET_DEVICE_PROFILE_SUCCESS,
  GET_DEVICE_PROFILE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  devices: [],
  deviceProfile: {},
  error: {},
}

const devices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload,
      }

    case GET_DEVICES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_DEVICE_SUCCESS:

      return {
        ...state,
        devices: [action.payload, ...state.devices ],
      }

    case ADD_DEVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DEVICE_PROFILE_SUCCESS:
      return {
        ...state,
        deviceProfile: action.payload,
      }

    case UPDATE_DEVICE_SUCCESS:
      return {
        ...state,
        devices: state.devices.map(device =>
          device.id.toString() === action.payload.id.toString()
            ? { device, ...action.payload }
            : device
        ),
      }

    case UPDATE_DEVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        devices: state.devices.filter(
          device => device.id.toString() !== action.payload.toString()
        ),
      }

    case DELETE_DEVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DEVICE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default devices
