import { patch, call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CATEGORIES, GET_CATEGORIES_BY_ID, GET_CATEGORY_PROFILE, ADD_NEW_CATEGORY, DELETE_CATEGORY, UPDATE_CATEGORY } from "./actionTypes"

import {
  getCategoriesSuccess,
  getCategoriesFail,
  getCategoryProfileSuccess,
  getCategoryProfileFail,
  addCategoryFail,
  addCategorySuccess,
  updateCategorySuccess,
  updateCategoryFail,
  deleteCategorySuccess,
  deleteCategoryFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getCategories, getCategoriesById, getCategoryProfile, addNewCategory, updateCategory, deleteCategory } from "../../helpers/helper"

function* fetchCategories() {
  try {
    const response = yield call(getCategories)
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

function* fetchCategoriesById({payload: category}) {
  try {
    const response = yield call(getCategories)
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

function* fetchCategoryProfile() {
  try {
    const response = yield call(getCategoryProfile)
    yield put(getCategoryProfileSuccess(response))
  } catch (error) {
    yield put(getCategoryProfileFail(error))
  }
}

function* onUpdateCategory({ payload: category }) {
  try {
    // delete category.id
    const id = category.get('id');

    const response = yield call(updateCategory, category, id)
    yield put(updateCategorySuccess(response))
  } catch (error) {
    yield put(updateCategoryFail(error))
  }
}

function* onDeleteCategory({ payload: category }) {
  try {
    const response = yield call(deleteCategory, category)
    yield put(deleteCategorySuccess(response))
  } catch (error) {
    yield put(deleteCategoryFail(error))
  }
}

function* onAddNewCategory({ payload: category }) {

  try {
    const response = yield call(addNewCategory, category)

    yield put(addCategorySuccess(response))
  } catch (error) {

    yield put(addCategoryFail(error))
  }
}

function* categoriesSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(GET_CATEGORIES_BY_ID, fetchCategoriesById)
  yield takeEvery(GET_CATEGORY_PROFILE, fetchCategoryProfile)
  yield takeEvery(ADD_NEW_CATEGORY, onAddNewCategory)
  yield takeEvery(UPDATE_CATEGORY, onUpdateCategory)
  yield takeEvery(DELETE_CATEGORY, onDeleteCategory)
}

export default categoriesSaga;
