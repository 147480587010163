/* REWARDS */
export const GET_REWARDS = "GET_REWARDS"
export const GET_REWARDS_BY_ID = "GET_REWARDS_BY_ID"
export const GET_REWARDS_SUCCESS = "GET_REWARDS_SUCCESS"
export const GET_REWARDS_FAIL = "GET_REWARDS_FAIL"

/* REWARDS PROFILE */
export const GET_REWARD_PROFILE = "GET_REWARD_PROFILE"
export const GET_REWARD_PROFILE_SUCCESS = "GET_REWARD_PROFILE_SUCCESS"
export const GET_REWARD_PROFILE_FAIL = "GET_REWARD_PROFILE_FAIL"

/**
 * add reward
 */
export const ADD_NEW_REWARD = "ADD_NEW_REWARD"
export const ADD_REWARD_SUCCESS = "ADD_REWARD_SUCCESS"
export const ADD_REWARD_FAIL = "ADD_REWARD_FAIL"

/**
 * Edit reward
 */
export const UPDATE_REWARD = "UPDATE_REWARD"
export const UPDATE_REWARD_SUCCESS = "UPDATE_REWARD_SUCCESS"
export const UPDATE_REWARD_FAIL = "UPDATE_REWARD_FAIL"

/**
 * Delete reward
 */
export const DELETE_REWARD = "DELETE_REWARD"
export const DELETE_REWARD_SUCCESS = "DELETE_REWARD_SUCCESS"
export const DELETE_REWARD_FAIL = "DELETE_REWARD_FAIL"
