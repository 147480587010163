import axios from "axios";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import TableContainer from "../../../components/Common/TableContainer";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  FormFeedback,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Name,
  Email,
  Description,
  Id,
  Tags,
  UpdatedAt,
  CreatedAt,
  Projects,
  Img,
} from "./answerlistCol";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getAnswers as onGetAnswers,
  getAnswersById as onGetAnswersById,
  addNewAnswer as onAddNewAnswer,
  updateAnswer as onUpdateAnswer,
  deleteAnswer as onDeleteAnswer,
} from "../../../store/actions";

import {
  getQuestions as onGetQuestions,
  getQuestionsById as onGetQuestionsById,
} from "../../../store/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const answersList = () => {
  document.title = "ACUSOLO | Admin & Dashboard";

  const durationnotify = () =>
    toast("Please select the question. ", {
      position: "top-center",
      hideProgressBar: false,
      className: "bg-danger text-white",
    });
  const errornotify = () =>
    toast("Error ! An error occurred.", {
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: false,
      className: "bg-danger text-white",
      transition: Slide,
    });
  const editorRef = useRef();
  const [editor, setEditor] = useState(false);
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  // const [ state, setState ]  = useState("All");
  const [selectedValue, setSelectedValue] = useState(
    "00000000-0000-0000-0000-000000000000"
  );
  const [selectedName, setSelectedName] = useState("All");
  const dispatch = useDispatch();
  const [answer, setanswer] = useState();
  const [question, setquestion] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const onSelectData = (value, name) => {
    setSelectedValue(value);
    setSelectedName(name);
    dispatch(onGetAnswersById(value));
  };

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    setEditor(true);
  }, []);
  const [data, setData] = useState("");
  const [editorData, setEditorData] = useState("");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    setanswer({
      ...answer,
      description: data,
    });
  };
  
  const handleSelectChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const value = selectedOption.value;
    const name = selectedOption.text; // This gets the text inside the option
    onSelectData(value, name);
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      qid: (answer && answer.qid) || "",
      qname: (answer && answer.qname) || "",
      description: (answer && answer.description) || "",
    },
    validationSchema: Yup.object({
      qname: Yup.string().required("Please Enter Quesiton Name"),
      description: Yup.string().required("Please Enter Answer Description"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateAnswer = {
          id: answer.id,
          // name: values.name,
          description: editorData,
        };
        // update answer
        dispatch(onUpdateAnswer(updateAnswer));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newAnswer = {
          qid: values["qid"],
          // qname: values["qname"],
          description: editorData,
        };
        // save new answer
        dispatch(onAddNewAnswer(newAnswer));
        validation.resetForm();
      }
      toggle();
    },
  });

  const answerlistData = createSelector(
    (state) => state.answers,
    (state) => ({
      answers: state.answers,
    })
  );

  const questionlistData = createSelector(
    (state) => state.questions,
    (state) => ({
      questions: state.questions,
    })
  );

  // Inside your component
  const { answers } = useSelector(answerlistData);
  const { questions } = useSelector(questionlistData);

  const columns = useMemo(
    () => [
      {  
        Header: "No",  
        Cell: ({ row, state }) => {  
          const { pageIndex, pageSize } = state; // Get pageIndex and pageSize from state  
          const rowIndex = row.index + 1 ;// Calculate the row number  
          return <label className="">{rowIndex}</label>;  
        },  
      },
      {
        Header: "Answer",
        accessor: "description",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Description value={cellProps.value} />;
        },
      },
      {
        Header: "CreatedAt",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CreatedAt {...cellProps} />;
        },
      },
      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <UpdatedAt {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const answerData = cellProps.row.original;
                    handleAnswerClick(answerData);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const answerData = cellProps.row.original;
                    onClickDelete(answerData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (questions && !questions.length && !isEmpty(questions)) {
      dispatch(onGetQuestions());
      setIsEdit(false);
    }
  }, [dispatch, questions]);

  useEffect(() => {
    if (answers && !answers.length && !isEmpty(answers)) {
      dispatch(onGetAnswers());
      setIsEdit(false);
    }
  }, [dispatch, answers]);

  useEffect(() => {
    dispatch(onGetAnswers());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    dispatch(onGetQuestions());
    setIsEdit(false);
  }, []);
  
  useEffect(() => {
    setanswer(answers);
    setIsEdit(false);
  }, [answers]);

  useEffect(() => {
    setquestion(questions);
    // setIsEdit(false);
  }, [questions]);

  useEffect(() => {
    if (!isEmpty(answers) && !!isEdit) {
      setanswer(answers);
      setIsEdit(false);
    }
  }, [answers]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleAnswerClick = (arg) => {    
    const answer = arg;
    setanswer({
      id: answer.id,
      qname: answer.qname,
      description: answer.description,
    });

    setIsEdit(true);

    toggle();
  };

  const handleAnswerClicks = (result) => {
    
    setanswer({
      qid: result.selectedValue,
      qname: result.selectedName,
    });

    setIsEdit(false);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete answer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (answers) => {
    setanswer(answers);
    setDeleteModal(true);
  };

  const handleDeleteAnswer = () => {
    dispatch(onDeleteAnswer(answer.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAnswer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Col lg="12">
                <Row className="align-items-center">
                  <Col>
                    <div className="mb-3">
                      <h5 className="card-title">
                        Answers List{" "}
                        <span className="text-muted fw-normal ms-2">
                          ({answers.length})
                        </span>
                      </h5>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                      <Card>
                        <CardHeader>
                          <p className="text-muted mb-0">
                            Please <code>select</code> question. Before add new answer, check selection Question.
                          </p>
                          <br></br>
                          <div className="row">
                            <div className="col-sm-7 col-md-7">
                              <div className="">
                                <div className="ms-auto">
                                  <select
                                    className="form-select form-select-md"
                                    onChange={handleSelectChange}
                                  >
                                    <option value="00000000-0000-0000-0000-000000000000">
                                      Select Questions...
                                    </option>
                                    {questions.map((question) => (
                                      <option
                                        key={question.id}
                                        value={question.id}
                                      >
                                        {question.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div
                              style={{ display: "flex" }}
                              className="col-sm-5 col-md-5 justify-content-end"
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={() => {
                                  if (
                                    selectedValue == "All" ||
                                    selectedValue ==
                                      "00000000-0000-0000-0000-000000000000"
                                  ) {
                                    durationnotify();
                                  } else {
                                    const result = {
                                      editorData,
                                      selectedName,
                                      selectedValue,
                                    };
                                    handleAnswerClicks(result);
                                  }
                                }}
                              >
                                <i className="bx bx-plus me-1"></i> Add new
                              </Link>
                            </div>
                          </div>
                        </CardHeader>
                      </Card>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={12}>
                    <TableContainer
                      columns={columns}
                      data={answers}
                      isGlobalFilter={true}
                      isAddAnswerList={true}
                      customPageSize={10}
                      className="table align-middle datatable dt-responsive table-check nowrap"
                    />
                    <Modal isOpen={modal} toggle={toggle}>
                      <ModalHeader toggle={toggle} tag="h4">
                        {!!isEdit ? "Edit Answer" : "Add Answer"}
                      </ModalHeader>
                      <ModalBody>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <Row>
                            <Col xs={12}>
                              <div className="mb-3">
                                <Label className="form-label">Question</Label>
                                <Input
                                  name="qname"
                                  label="Qname"
                                  type="text"
                                  placeholder="Insert Question"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.qname || ""}
                                  invalid={
                                    validation.touched.qname &&
                                    validation.errors.qname
                                      ? true
                                      : false
                                  }
                                />
                                <Input
                                  name="qid"
                                  label="Qid"
                                  type="text"
                                  placeholder="Insert Question"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.qid || ""}
                                  invalid={
                                    validation.touched.qid &&
                                    validation.errors.qid
                                      ? true
                                      : false
                                  }
                                  style={{ visibility: "hidden" }}
                                />
                                {validation.touched.qid &&
                                validation.errors.qid ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.qid}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3">
                                <Label className="form-label">
                                  Description
                                </Label>
                                <div className="form-control">
                                  {editor ? (
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={validation.values.description}
                                      onReady={() => {
                                        // You can store the "editor" and use when it is needed.
                                      }}
                                      onChange={handleEditorChange}
                                    />
                                  ) : (
                                    <p>Loading editor.....</p>
                                  )}
                                </div>
                                <br></br>                  
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary save-answer"
                                >
                                  {!!isEdit ? "Save" : "Create"}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </ModalBody>
                    </Modal>
                  </Col>
                </Row>
              </Col>
            </Col>
            {/* <Col xl={4}>
            </Col> */}
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default answersList;
