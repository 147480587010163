import axios from "axios";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";

import {
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  Input,
  FormFeedback,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Name,
  Email,
  Description,
  Id,
  Tags,
  PhoneNumber,
  UserName,
  SubscriptionValue,
  UpdatedAt,
  CreatedAt,
  IsLocked,
} from "./userlistCol";

import { countries } from "countries-list";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "../../../store/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const usersList = () => {
  document.title = "ACUSOLO | Admin & Dashboard";

  const dispatch = useDispatch();
  const [user, setuser] = useState();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLocked, setIsChecked] = useState(true);
  const [selectedCountryCode, setSelectedCountryCode] = useState("0");
  const [errors, setErrors] = useState([{ phoneNumber: null }]);

  const countryList = Object.values(countries).map((country) => ({
    name: country.name,
    code: country.phone,
  }));

  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber.startsWith(selectedCountryCode)) {
      return "Phone number must start with the selected country code.";
    }
    return null;
  };

  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value;

    validation.handleChange(e);
    const error = validatePhoneNumber(phoneNumber);
    // console.log({error});
    if (error) {
      validation.setFieldError("phoneNumber", error);
      setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: error }));
    } else {
      setErrors((prevErrors) => {
        const { phoneNumber, ...rest } = prevErrors; // Remove phoneNumber error if no error
        return rest;
      });
    }
    // console.log({validation});
    console.log({ errors });
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userName: (user && user.userName) || "",
      email: (user && user.email) || "",
      phoneNumber: (user && user.phoneNumber) || "",
      subscriptionValue: (user && user.subscriptionValue) || "",
      isLocked: (user && user.isLocked) || false,
    },
    validationSchema: Yup.object({
      userName: Yup.string().required("Please Enter Your Name"),
      email: Yup.string().required("Please Enter Your Email"),
      phoneNumber: Yup.string().required("Please Enter Your Phone Number"),
      subscriptionValue: Yup.string().required(
        "Please Select Your Subscription"
      ),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateUser = {
          id: user.id,
          userName: values.userName,
          phoneNumber: values.phoneNumber,
          email: values.email,
          subscriptionValue: values.subscriptionValue,
          isLocked: isLocked,
        };

        // update user
        dispatch(onUpdateUser(updateUser));
        validation.resetForm();
        setIsEdit(false);
      } else {
        const newUser = {
          userName: values["userName"],
          email: values["email"],
          phoneNumber: values["phoneNumber"],
          subscriptionValue: values["subscriptionValue"],
          password: "123456",
        };
        // save new user
        dispatch(onAddNewUser(newUser));

        setuser((prevUsers) => [newUser, ...prevUsers]);

        validation.resetForm();
      }
      toggle();
    },
  });

  const userlistData = createSelector(
    (state) => state.users,
    (state) => ({
      users: state.users,
    })
  );

  const handleToggleIsLocked = () => {
    setIsChecked((prevState) => !prevState);
  };

  // Inside your component
  const { users } = useSelector(userlistData);

  const columns = useMemo(
    () => [
      {
        Header: "No",
        Cell: ({ row, state }) => {
          const { pageIndex, pageSize } = state; // Get pageIndex and pageSize from state
          const rowIndex = row.index + 1; // Calculate the row number
          return <label className="">{rowIndex}</label>;
        },
      },
      {
        Header: "Name",
        accessor: "userName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <UserName {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "PhoneNumber",
        accessor: "phoneNumber",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <PhoneNumber {...cellProps} />;
        },
      },
      {
        Header: "Subscription",
        accessor: "subscriptionValue",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <SubscriptionValue {...cellProps} />;
        },
      },

      {
        Header: "CreatedAt",
        accessor: "createdAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <CreatedAt {...cellProps} />;
        },
      },

      {
        Header: "UpdatedAt",
        accessor: "updatedAt",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <UpdatedAt {...cellProps} />;
        },
      },
      {
        Header: "Active",
        accessor: "isLocked",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <IsLocked {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    handleUserClick(userData);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    onClickDelete(userData);
                  }}
                ></i>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length && !isEmpty(users)) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    dispatch(onGetUsers());
    setIsEdit(false);
  }, []);

  useEffect(() => {
    setuser(users);
    setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setuser(users);
      setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setuser({
      id: user.id,
      userName: user.userName,
      phoneNumber: user.phoneNumber,
      subscriptionValue: user.subscriptionValue,
      email: user.email,
      isLocked: user.isLocked,
    });

    setIsChecked(user.isLocked);

    setIsEdit(true);

    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete user
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (users) => {
    setuser(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    dispatch(onDeleteUser(user.id));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const keyField = "id";

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="users" breadcrumbItem="User List" /> */}
          <Row>
            <Col lg="12">
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="mb-3">
                    <h5 className="card-title">
                      Users List{" "}
                      <span className="text-muted fw-normal ms-2">
                        ({users.length})
                      </span>
                    </h5>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                    {/* <div>
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <NavLink className="active" href="/users-list" data-bs-toggle="tooltip"  data-bs-placement="top" id="list">
                            <i className="bx bx-list-ul"></i>
                            <UncontrolledTooltip placement="top" target="list"> List</UncontrolledTooltip>
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink  href="/users-grid"  data-bs-toggle="tooltip" data-bs-placement="top" id="grid"  >
                            <i className="bx bx-grid-alt"></i>
                            <UncontrolledTooltip placement="top" target="grid">
                              Grid
                            </UncontrolledTooltip>
                          </NavLink>
                        </li>
                      </ul>
                    </div> */}
                    <div>
                      <Link
                        to="#"
                        className="btn btn-primary"
                        onClick={handleUserClicks}
                      >
                        <i className="bx bx-user-plus me-1"></i> Add new
                      </Link>
                    </div>

                    {/* <UncontrolledDropdown>
                      <DropdownToggle className="btn btn-link text-muted py-1 font-size-16 shadow-none" tag="a" >
                        <i className="bx bx-dots-horizontal-rounded"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <li><DropdownItem to="#">Action</DropdownItem></li>
                        <li><DropdownItem to="#">Another action</DropdownItem></li>
                        <li><DropdownItem to="#">Something else here</DropdownItem></li>
                      </DropdownMenu>
                    </UncontrolledDropdown> */}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl="12">
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    // isAddOptions={true}
                    customPageSize={10}
                    theadClass={"table-primary"}
                    className="table align-middle datatable dt-responsive table-check nowrap"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit User" : "Add User"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          // handleFormSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="userName"
                                label="UserName"
                                type="text"
                                placeholder="Insert Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.userName || ""}
                                invalid={
                                  validation.touched.userName &&
                                  validation.errors.userName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.userName &&
                              validation.errors.userName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.userName}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                label="Email"
                                type="text"
                                placeholder="Insert Email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="form-group">
                              <Label className="form-label mr-2">
                                Phone Number
                              </Label>
                              <Row>
                                <Col lg={4} sm={4}>
                                  <Input
                                    type="select"
                                    onChange={(e) =>
                                      setSelectedCountryCode(e.target.value)
                                    }
                                    value={selectedCountryCode}
                                  >
                                    <option value="0">Select code...</option>
                                    {countryList.map((country, index) => (
                                      <option
                                        key={`${country.code}-${index}`}
                                        value={country.code}
                                      >
                                        {country.name} ({country.code})
                                      </option>
                                    ))}
                                  </Input>
                                </Col>
                                <Col lg={8} sm={8}>
                                  <Input
                                    name="phoneNumber"
                                    className="form-control"
                                    placeholder="Enter Phone Number"
                                    type="text"
                                    onChange={handlePhoneNumberChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phoneNumber || ""}
                                    invalid={
                                      (validation.touched.phoneNumber &&
                                        validation.errors.phoneNumber) ||
                                      errors.phoneNumber
                                        ? true
                                        : false
                                    }
                                    // invalid={
                                    //   validation.touched.phoneNumber &&
                                    //   validation.errors.phoneNumber
                                    //     ? true
                                    //     : false
                                    // }
                                  />
                                  {validation.touched.phoneNumber &&
                                  validation.errors.phoneNumber ? (
                                    <FormFeedback
                                      type="invalid"
                                      style={{ display: "block" }}
                                    >
                                      {validation.errors.phoneNumber}
                                    </FormFeedback>
                                  ) : errors.phoneNumber ? (
                                    <FormFeedback
                                      type="invalid"
                                      style={{ display: "block" }}
                                    >
                                      {errors.phoneNumber}
                                    </FormFeedback>
                                  ) : null}
                                </Col>
                              </Row>
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Subscription</Label>
                              <Input
                                name="subscriptionValue"
                                label="SubscriptionValue"
                                type="select"
                                placeholder="Insert Subscription"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.subscriptionValue || ""
                                }
                                invalid={
                                  validation.touched.subscriptionValue &&
                                  validation.errors.subscriptionValue
                                    ? true
                                    : false
                                }
                              >
                                <option value="Free">
                                  Select a Subscription
                                </option>
                                <option value="1">1 month</option>
                                <option value="3">3 months</option>
                                <option value="6">6 months</option>
                                <option value="12">12 months</option>
                              </Input>
                              {validation.touched.subscriptionValue &&
                              validation.errors.subscriptionValue ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.subscriptionValue}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {!!isEdit ? (
                              <div className="square-switch">
                                <input
                                  type="checkbox"
                                  id="square-switch1"
                                  // eslint-disable-next-line react/no-unknown-property
                                  switch="none"
                                  checked={isLocked}
                                  onChange={handleToggleIsLocked}
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="On"
                                  data-off-label="Off"
                                ></label>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-primary save-user"
                              >
                                {!!isEdit ? "Save" : "Create"}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default usersList;
