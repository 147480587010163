/* SESSION12S */
export const GET_SESSION12S = "GET_SESSION12S"
export const GET_SESSION12S_BY_ID = "GET_SESSION12S_BY_ID"
export const GET_SESSION12S_SUCCESS = "GET_SESSION12S_SUCCESS"
export const GET_SESSION12S_FAIL = "GET_SESSION12S_FAIL"

/* SESSION12S PROFILE */
export const GET_SESSION12_PROFILE = "GET_SESSION12_PROFILE"
export const GET_SESSION12_PROFILE_SUCCESS = "GET_SESSION12_PROFILE_SUCCESS"
export const GET_SESSION12_PROFILE_FAIL = "GET_SESSION12_PROFILE_FAIL"

/**
 * add session12
 */
export const ADD_NEW_SESSION12 = "ADD_NEW_SESSION12"
export const ADD_SESSION12_SUCCESS = "ADD_SESSION12_SUCCESS"
export const ADD_SESSION12_FAIL = "ADD_SESSION12_FAIL"

/**
 * Edit session12
 */
export const UPDATE_SESSION12 = "UPDATE_SESSION12"
export const UPDATE_SESSION12_SUCCESS = "UPDATE_SESSION12_SUCCESS"
export const UPDATE_SESSION12_FAIL = "UPDATE_SESSION12_FAIL"

/**
 * Delete session12
 */
export const DELETE_SESSION12 = "DELETE_SESSION12"
export const DELETE_SESSION12_SUCCESS = "DELETE_SESSION12_SUCCESS"
export const DELETE_SESSION12_FAIL = "DELETE_SESSION12_FAIL"
