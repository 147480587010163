import {
  GET_ANSWER_PROFILE,
  GET_ANSWER_PROFILE_FAIL,
  GET_ANSWER_PROFILE_SUCCESS,
  GET_ANSWERS,
  GET_ANSWERS_BY_ID,
  GET_ANSWERS_FAIL,
  GET_ANSWERS_SUCCESS,
  ADD_NEW_ANSWER,
  ADD_ANSWER_SUCCESS,
  ADD_ANSWER_FAIL,
  UPDATE_ANSWER,
  UPDATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_FAIL,
  DELETE_ANSWER,
  DELETE_ANSWER_SUCCESS,
  DELETE_ANSWER_FAIL,
} from "./actionTypes"

export const getAnswers = () => ({
  type: GET_ANSWERS,
})

export const getAnswersById = answer => ({
  type: GET_ANSWERS_BY_ID,
  payload: answer,
})

export const getAnswersSuccess = answers => ({
  type: GET_ANSWERS_SUCCESS,
  payload: answers,
})

export const addNewAnswer = answer => ({
  type: ADD_NEW_ANSWER,
  payload: answer,
})

export const addAnswerSuccess = answer => ({
  type: ADD_ANSWER_SUCCESS,
  payload: answer,
})

export const addAnswerFail = error => ({
  type: ADD_ANSWER_FAIL,
  payload: error,
})

export const getAnswersFail = error => ({
  type: GET_ANSWERS_FAIL,
  payload: error,
})

export const getAnswerProfile = () => ({
  type: GET_ANSWER_PROFILE,
})

export const getAnswerProfileSuccess = answerProfile => ({
  type: GET_ANSWER_PROFILE_SUCCESS,
  payload: answerProfile,
})

export const getAnswerProfileFail = error => ({
  type: GET_ANSWER_PROFILE_FAIL,
  payload: error,
})

export const updateAnswer = answer => ({
  type: UPDATE_ANSWER,
  payload: answer,
})

export const updateAnswerSuccess = answer => ({
  type: UPDATE_ANSWER_SUCCESS,
  payload: answer,
})

export const updateAnswerFail = error => ({
  type: UPDATE_ANSWER_FAIL,
  payload: error,
})

export const deleteAnswer = answer => ({
  type: DELETE_ANSWER,
  payload: answer,
})

export const deleteAnswerSuccess = answer => ({
  type: DELETE_ANSWER_SUCCESS,
  payload: answer,
})

export const deleteAnswerFail = error => ({
  type: DELETE_ANSWER_FAIL,
  payload: error,
})
