import {
  GET_DEVICE_PROFILE,
  GET_DEVICE_PROFILE_FAIL,
  GET_DEVICE_PROFILE_SUCCESS,
  GET_DEVICES,
  GET_DEVICES_FAIL,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_BY_ID,
  ADD_NEW_DEVICE,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAIL,
  UPDATE_DEVICE,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAIL,
  DELETE_DEVICE,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAIL,
} from "./actionTypes"

export const getDevices = () => ({
  type: GET_DEVICES,
})

export const getDevicesById = devices => ({
  type: GET_DEVICES_BY_ID,
  payload: devices,
})

export const getDevicesSuccess = devices => ({
  type: GET_DEVICES_SUCCESS,
  payload: devices,
})

export const addNewDevice = device => ({
  type: ADD_NEW_DEVICE,
  payload: device,
})

export const addDeviceSuccess = device => ({
  type: ADD_DEVICE_SUCCESS,
  payload: device,
})

export const addDeviceFail = error => ({
  type: ADD_DEVICE_FAIL,
  payload: error,
})

export const getDevicesFail = error => ({
  type: GET_DEVICES_FAIL,
  payload: error,
})

export const getDeviceProfile = () => ({
  type: GET_DEVICE_PROFILE,
})

export const getDeviceProfileSuccess = deviceProfile => ({
  type: GET_DEVICE_PROFILE_SUCCESS,
  payload: deviceProfile,
})

export const getDeviceProfileFail = error => ({
  type: GET_DEVICE_PROFILE_FAIL,
  payload: error,
})

export const updateDevice = device => ({
  type: UPDATE_DEVICE,
  payload: device,
})

export const updateDeviceSuccess = device => ({
  type: UPDATE_DEVICE_SUCCESS,
  payload: device,
})

export const updateDeviceFail = error => ({
  type: UPDATE_DEVICE_FAIL,
  payload: error,
})

export const deleteDevice = device => ({
  type: DELETE_DEVICE,
  payload: device,
})

export const deleteDeviceSuccess = device => ({
  type: DELETE_DEVICE_SUCCESS,
  payload: device,
})

export const deleteDeviceFail = error => ({
  type: DELETE_DEVICE_FAIL,
  payload: error,
})
